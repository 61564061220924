import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'

export default class NewSample extends React.Component {
  static propTypes = {
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      date: moment().format(),
      code: '',
    }
  }

  handleChange = (e) => {
    let value, propName = e.target.name
    if (e.target.name == 'form_ids') {
      let options = e.target.options
      value = []
      for (let i = 0, l = options.length; i < l; i++) {
        if (options[i].selected) {
          value.push(options[i].value)
        }
      }
      value.sort()
    }
    else {
      value = e.target.value
    }
    this.setState({
      [propName]: value,
    })
  }

  render() {
    return (
      <div className="small-box bg-yellow">
        <div className="inner">
          <div className="form-group">
            <input autoFocus className="form-control" name="code" onChange={this.handleChange} placeholder="sample code ..." size="50" type="text" value={this.state.code} />
          </div>
          <div className="form-group">
            <input className="form-control" name="date" onChange={this.handleChange} type="date" value={this.state.date} />
          </div>
        </div>
        <div className="small-box-footer">
          <div className="text-right pr-1">
            <div className="btn-group">
              <Button onClick={this.props.onCancel} size="sm" variant="link">cancel</Button>
              <Button disabled={this.props.loading} onClick={() => this.props.onSave(this.state)} size="sm" type="submit" variant="primary">save</Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
