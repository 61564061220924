import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import * as XLSX from 'xlsx'
import Papa from 'papaparse'
import * as FileSaver from 'file-saver'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
} from '@tanstack/react-table'
import BTable from 'react-bootstrap/Table'
import { DropdownButton, Dropdown } from 'react-bootstrap'
import styled from 'styled-components'
import LoadingOverlay from 'react-loading-overlay'
import { useFirstRender } from '../utils'
LoadingOverlay.propTypes = undefined // workaround to remove warning https://github.com/derrickpelletier/react-loading-overlay/pull/57#issuecomment-1054194254

// need to be placed outside the function Component otherwise it will be redefined on every run cousing a complete remount
const Styles = styled.div`
  table {
    thead {
      th {
        vertical-align: top;
        input {
          width: 100%;
        }
      }
    }
    tr {
      cursor: pointer;
      td {
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
`

const exportExcel = ({ data, attributes }) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  let outData,
    wb = { Sheets: {}, SheetNames: [] },
    header = _.map(attributes, 'name')

  wb.Sheets['data'] = XLSX.utils.aoa_to_sheet([header].concat(data ? data.map(row => _.map(attributes, (attrs, name) => renderCell(row[name], attrs.type))) : []))
  wb.SheetNames.push('data')

  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
  outData = new Blob([excelBuffer], { type: fileType })
  FileSaver.saveAs(outData, 'data.xlsx')
}

const exportCSV = ({ data, attributes }) => {
  const fileType = 'text/csv;charset=UTF-8',
    header = _.map(attributes, 'name'),
    csv = Papa.unparse([header].concat(data.map(row => _.map(attributes, (attrs, name) => renderCell(row[name], attrs.type)))), { delimiter: '\t' }),
    outData = new Blob([csv], { type: fileType })
  FileSaver.saveAs(outData, 'data.csv')
}

// A debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}) {
  const [value, setValue] = React.useState(initialValue)

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return (
    <input {...props} onChange={e => setValue(e.target.value)} value={value} />
  )
}

function StringFilter({
  column,
  table,
}) {
  const columnFilterValue = column.getFilterValue()

  return (
    <DebouncedInput
      list={column.id + 'list'}
      onChange={value => column.setFilterValue(value)}
      placeholder="Search... "
      type="text"
      value={(columnFilterValue ?? '')}
    />
  )
}

StringFilter.propTypes = {
  column: PropTypes.object.isRequired,
  table: PropTypes.object.isRequired,
}

function renderCell(value, type) {
  if (_.isNil(value)) {
    return null
  }
  switch (type) {
    case 'date':
      return moment(value).format('YYYY-MM-DD')
    case 'datetime':
      return moment(value).format('YYYY-MM-DD HH:mm')
    case 'array':
      return _.join(value, ', ')
    case 'bool':
      return _.isNil(value) ? '' : value.toString()
    default:
      return value
  }
}

const columnHelper = createColumnHelper()

function Table({
  attributes,
  actionCell,
  data,
  fetchData,
  loading,
  onClickRow,
  pageCount,
  totResults,
  initialSorting = [],
  downloadEnabled = false,
}) {
  const columns = useMemo(() => {
    let res = _.map(attributes, (attrProps, a) => {
      return columnHelper.accessor(a, {
        header: attrProps.name,
        enableColumnFilter: attrProps.type == 'string' || attrProps.type == 'array' || attrProps.type == 'date' || attrProps.type == 'datetime',
        cell: info => renderCell(info.getValue(), attrProps.type),
      })
    })

    if (actionCell) {
      res.push(
        columnHelper.display({
          id: 'actions',
          cell: props => actionCell(props.row.original),
        })
      )
    }
    return res
  }, [attributes])

  const [{ pageIndex, pageSize }, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 30,
  })

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  )

  const [sorting, setSorting] = React.useState(initialSorting)

  const [columnFilters, setColumnFilters] = React.useState([])

  const table = useReactTable({
    data,
    columns,
    pageCount: pageCount,
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination,
      sorting,
      columnFilters,
    },
    onPaginationChange: setPagination,
    manualPagination: true,
    onSortingChange: setSorting,
    manualSorting: true,
    sortDescFirst: false,
    onColumnFiltersChange: setColumnFilters,
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  })

  const fetchDataDebounced = useCallback(_.debounce(fetchData, 2000), [])

  const firstRender = useFirstRender()

  // reload data debounced after the first render when columnFilters changes (so that you can change more filters before firing the request)
  React.useEffect(() => {
    !firstRender && fetchDataDebounced({ pageIndex, pageSize, sorting, columnFilters })
  }, [JSON.stringify(columnFilters)])

  // reload data as soon as pageIndex, pageSize, sorting change (it includes the first render)
  React.useEffect(() => {
    fetchData({ pageIndex, pageSize, sorting, columnFilters })
  }, [pageIndex, pageSize, JSON.stringify(sorting)])

  // go to first page when sorting and filters change
  React.useEffect(() => {
    setPagination({
      pageIndex: 0,
      pageSize: pagination.pageSize,
    })
  }, [sorting, columnFilters])

  // Render the UI for your table
  return (
    <Styles>
      <LoadingOverlay
        active={loading}
        styles={{
          overlay: base => ({
            ...base,
            background: 'rgba(0, 0, 0, 0.3)',
          }),
        }}
        text="Loading ..."
      >
        <BTable hover responsive size="sm">
          <thead className="thead-light">
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th colSpan={header.colSpan} key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : (
                        <>
                          <div
                            onClick={header.column.getToggleSortingHandler()}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: ' ▲',
                              desc: ' ▼',
                            }[header.column.getIsSorted()] ?? null}
                          </div>

                          {header.column.getCanFilter()
                            ? (
                              <div>
                                <StringFilter column={header.column} table={table} />
                              </div>
                              )
                            : null}
                        </>
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <td
                    key={cell.id}
                    onClick={e => onClickRow && cell.column.id != 'actions' ? onClickRow(e, cell.row.original) : {}}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </BTable>
        <div className="bg-light p-2 mb-2 d-flex justify-content-between">
          <p className="mb-0">
            {
              table.getRowModel().rows.length < totResults
                ? (
                  <span className="mr-1">
                    Page:
                    {' '}
                    {pageIndex + 1}
                    /
                    {table.getPageCount()}
                  </span>
                  )
                : null
            }
            {`Total Results: ${totResults}`}
          </p>
          <div>
            {
              table.getRowModel().rows.length < totResults
                ? (
                  <ul className="pagination pagination-sm mb-0">
                    <li className={'page-item' + (table.getCanPreviousPage() ? '' : ' disabled')}>
                      <a className="page-link" onClick={() => table.setPageIndex(0)}>
                        first
                      </a>
                    </li>
                    <li className={'page-item' + (table.getCanPreviousPage() ? '' : ' disabled')}>
                      <a className="page-link" onClick={() => table.previousPage()}>
                        previous
                      </a>
                    </li>
                    <li className={'page-item' + (table.getCanNextPage() ? '' : ' disabled')}>
                      <a className="page-link" onClick={() => table.nextPage()}>
                        next
                      </a>
                    </li>
                    <li className={'page-item' + (table.getCanNextPage() ? '' : ' disabled')}>
                      <a className="page-link" onClick={() => table.setPageIndex(table.getPageCount() - 1)}>
                        last
                      </a>
                    </li>
                  </ul>
                  )
                : null
            }
          </div>
          <div>
            <DropdownButton size="sm" title={`${table.getState().pagination.pageSize} per page`} variant="outline-secondary">
              {[5, 10, 30, 100, 500].map(pageSize => (
                <Dropdown.Item
                  key={pageSize}
                  onClick={() => {
                    table.setPageSize(pageSize)
                    table.setPageIndex(0)
                  }}
                >
                  {`${pageSize} per page`}
                </Dropdown.Item>
              )
              )}
            </DropdownButton>
          </div>
        </div>
        {
          downloadEnabled
            ? (
              <div className="float-right">
                <DropdownButton
                  className="mr-2"
                  disabled={_.isEmpty(table.getRowModel().rows)}
                  size="sm"
                  title={(
                    <span>
                      <i className="fas fa-download" />
                      {' '}
                      download
                    </span>
                  )}
                  variant="secondary"
                >
                  <Dropdown.Item onClick={() => exportCSV({
                    data: data,
                    attributes: attributes,
                  })}
                  >
                    CSV
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => exportExcel({
                    data: data,
                    attributes: attributes,
                  })}
                  >
                    Excel
                  </Dropdown.Item>
                </DropdownButton>
              </div>
              )

            : null
        }
        {/* <pre>{JSON.stringify(table.getState(), null, 2)}</pre> */}

      </LoadingOverlay>
    </Styles>
  )
}

Table.propTypes = {
  attributes: PropTypes.object.isRequired,
  actionCell: PropTypes.func,
  data: PropTypes.array.isRequired,
  fetchData: PropTypes.func.isRequired,
  initialSelectedRowIds: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  onClickRow: PropTypes.func.isRequired,
  pageCount: PropTypes.number.isRequired,
  rowId: PropTypes.string,
  totResults: PropTypes.number.isRequired,
  initialSorting: PropTypes.array,
  downloadEnabled: PropTypes.bool,
}

const memoizedTable = React.memo(Table)
memoizedTable.displayName = 'Table'

export default memoizedTable
