import React, { memo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ButtonGroup, Form, Button, Card } from 'react-bootstrap'
import { usertimestampedBy } from '../../utils'

function ViewsSelector({
  list,
  currentView,
  isCurrentViewChangedContent,
  onCreate,
  onUpdate,
  onSelect,
  onDelete,
  onDuplicate,
}) {
  // console.debug('rendering ViewsSelector')

  const [viewName, setViewName] = useState('')
  const [viewIsPublic, setViewIsPublic] = useState(false)
  const [formMode, setFormMode] = useState(false)

  const inputField = React.createRef()

  useEffect(() => {
    if (formMode) {
      if (currentView) {
        setViewName(currentView.name)
        setViewIsPublic(!currentView.private)
      }
      else {
        setViewName('')
        setViewIsPublic(false)
        inputField.current && inputField.current.focus()
      }
    }
  }, [formMode, currentView])

  useEffect(() => {
    if (currentView) {
      setFormMode(isCurrentViewChangedContent)
    }
  }, [isCurrentViewChangedContent, currentView])

  const createView = () => onCreate(viewName, viewIsPublic)

  const updateView = () => onUpdate(viewName, viewIsPublic)

  const deleteView = () => onDelete()

  const handleViewNameChanged = e => setViewName(e.target.value)

  const handleViewIsPublicChanged = e => setViewIsPublic(e.target.checked)

  const selectView = (e) => {
    onSelect(e.target.value && parseInt(e.target.value))
  }

  const viewOptions = list.map((v) => {
    let label = `${v.name} - ${v.creator} ${v.private ? '' : 'PUBLIC'}`
    return <option key={v.id} value={v.id}>{label}</option>
  })

  let statusMessage

  if (currentView) {
    if (isCurrentViewChangedContent) {
      statusMessage = (
        <span>
          <i className="fa fa-exclamation-triangle text-warning" />
          {' '}
          Current view has changed.
          {' '}
          <strong>Remember to save!</strong>
        </span>
      )
    }
    else {
      statusMessage = (
        <span>
          <i className="fa fa-info-circle text-info" />
          {' '}
          {formMode
            ? 'Edit view data'
            : (
                <span>
                  A view is active,
                  <a className="text-info ml-1 mr-1" onClick={() => onSelect()}>click here to deactivate.</a>
                  {
                    !currentView.manageable
                      ? 'You cannot change this view: clone it to make changes'
                      : null
                  }
                </span>
              )}
        </span>
      )
    }
  }
  else {
    statusMessage = (
      <span>
        <i className="fa fa-info-circle" />
        {' '}
        {formMode ? 'Insert view data' : 'Select a view or create a new one'}
      </span>
    )
  }

  return (
    <Card bsPrefix={`card${list.length ? '' : ' collapsed-card'} card-${currentView ? (isCurrentViewChangedContent ? 'warning' : 'info') : 'secondary'} card-compact`}>
      <Card.Header>
        <Card.Title>
          Views manager
        </Card.Title>
        <div className="card-tools">
          <button className="btn btn-tool" data-card-widget="collapse" type="button">
            <i className={`fas ${list.length ? 'fa-minus' : 'fa-plus'}`} />
          </button>
        </div>
      </Card.Header>
      <Card.Body>
        {
          formMode
            ? (
                <Form inline>
                  <Form.Control
                    className="mr-2"
                    htmlSize="50"
                    onChange={handleViewNameChanged}
                    placeholder="Enter a name ..."
                    ref={ref => inputField.current = ref}
                    type="text"
                    value={viewName}
                  />
                  <Form.Check className="mr-2">
                    <Form.Check.Label>
                      <Form.Check.Input checked={viewIsPublic} onChange={handleViewIsPublicChanged} type="checkbox" />
                      public
                    </Form.Check.Label>
                  </Form.Check>
                  <ButtonGroup className="ml-auto">
                    {
                      currentView
                        ? (
                            <>
                              <Button
                                onClick={() => {
                                  onSelect(currentView.id)
                                  setViewName(currentView.name)
                                  setViewIsPublic(!currentView.private)
                                  setFormMode(false)
                                }}
                                variant="link"
                              >
                                <i className="fas fa-undo" />
                                {' '}
                                {
                                  isCurrentViewChangedContent
                                    ? 'undo changes'
                                    : 'cancel'
                                }
                              </Button>
                              <Button onClick={updateView} variant="primary">
                                <i className="fa fa-check" />
                                {' '}
                                save
                              </Button>
                            </>
                          )
                        : (
                            <>
                              <Button
                                onClick={() => {
                                  setViewName('')
                                  setViewIsPublic(false)
                                  setFormMode(false)
                                }}
                                variant="link"
                              >
                                <i className="fas fa-undo" />
                                {' '}
                                cancel
                              </Button>
                              <Button onClick={createView} variant="primary">
                                <i className="fa fa-check" />
                                {' '}
                                save
                              </Button>
                            </>
                          )
                    }
                  </ButtonGroup>
                </Form>
              )
            : (
                <Form className={!currentView ? 'justify-content-between' : null} inline>
                  <Form.Group bsPrefix="form-group visible-*-inline-block" controlId="formControlsSelect">
                    <Form.Control as="select" onChange={selectView} placeholder="select" value={currentView?.id || ''}>
                      <option value="">Select a view ...</option>
                      {viewOptions}
                    </Form.Control>
                  </Form.Group>
                  {
                    currentView
                      ? (
                          <ButtonGroup className="ml-2">
                            {
                              currentView.manageable
                                ? (
                                    <>
                                      <Button onClick={() => setFormMode(true)} variant="link">
                                        <span className="text-warning">
                                          <i className="fa fa-pencil-alt" />
                                          {' '}
                                          edit
                                        </span>
                                      </Button>
                                      <Button onClick={deleteView} variant="link">
                                        <span className="text-danger">
                                          <i className="fa fa-trash" />
                                          {' '}
                                          delete
                                        </span>
                                      </Button>
                                    </>
                                  )
                                : null
                            }
                            <Button onClick={onDuplicate} variant="link">
                              <i className="fa fa-clone" />
                              {' '}
                              clone
                            </Button>
                          </ButtonGroup>
                        )
                      : !currentView && onCreate
                          ? (
                              <Button className="ml-2 float-right" onClick={() => setFormMode('new')}>
                                <i className="fa fa-plus-circle" />
                                {' '}
                                Save to a new view
                              </Button>
                            )
                          : null
                  }
                </Form>
              )
        }
      </Card.Body>
      <Card.Footer>
        {statusMessage}
        {
          currentView
            ? (
                <div className="float-right">
                  {usertimestampedBy(currentView.updated_at, currentView.updater, 'Updated')}
                </div>
              )
            : null
        }
      </Card.Footer>
    </Card>
  )
}

ViewsSelector.propTypes = {
  list: PropTypes.array,
  currentView: PropTypes.object,
  isCurrentViewChangedContent: PropTypes.bool,
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDuplicate: PropTypes.func.isRequired,
}
export default memo(ViewsSelector)
