import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import CollectorLegendModal from './collector_legend_modal'

function LegendWidgetWidget(props) {
  const [showLegend, setShowLegend] = useState(false)

  return (
    <>
      <Button onClick={() => setShowLegend(true)} variant="secondary">
        <i className="fa fa-list" />
        {' '}
        Legend
      </Button>

      <CollectorLegendModal collector={showLegend ? props.collector : undefined} handleClose={() => setShowLegend(false)} />
    </>
  )
}

LegendWidgetWidget.propTypes = {
  collector: PropTypes.object,
}
export default LegendWidgetWidget
