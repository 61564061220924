import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'

export default class Relative extends React.Component {
  constructor(props) {
    super(props)
  }

  static defaultProps = {
    onlyRead: true,
  }

  static propTypes = {
    relative: PropTypes.object,
    role: PropTypes.string.isRequired,
    onUnlink: PropTypes.func,
    onlyRead: PropTypes.bool,
  }

  _startUnlinkingRelative = (e) => {
    e.preventDefault()
    this.props.onUnlink(this.props.relative.id, this.props.role)
  }

  render() {
    const p = this.props.relative
    let name, dates

    const genderIcon = (person) => {
      switch (person.sex) {
        case 'male':
          return <i aria-hidden="true" className="fa fa-mars" />
        case 'female':
          return <i aria-hidden="true" className="fa fa-venus" />
        default:
          return null
      }
    }

    const cohorts = p.cohorts && p.cohorts.map(c => <div className="badge cohort-badge" key={c.id}>{c.name}</div>)

    if (!_.isNil(p)) {
      dates = _.map(_.compact([p.dob, p.dod]), d => moment(d).format()).join(' → ')
      name = (
        <h6>
          {p.fullname || p.id}
          <small>
            <br />
            {dates}
          </small>
        </h6>
      )
    }

    return (
      <a href={p ? p.path_to_relatives_home : null}>
        <div className="small-box bg-info mb-0">
          <div className="inner">
            <h5>{_.upperCase(this.props.role)}</h5>
            {p ? name : null}
            { cohorts }
          </div>
          <div className="icon">
            {genderIcon(p)}
          </div>
          {
            !this.props.onlyRead
              ? (
                <div className="small-box-footer text-right pr-1">
                  <Button onClick={this._startUnlinkingRelative} size="sm" variant="warning">unlink</Button>
                </div>
                )
              : null
          }
        </div>
      </a>
    )
  }
}
