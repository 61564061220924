import React, { memo, useMemo, useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Card } from 'react-bootstrap'
import { xFetch, WithSpinner } from '../../utils'
import Workbook from './workbook'
import LiftUpManager from './lift_up_manager'
import styled from 'styled-components'
import LiftUpHistory from './liftup_history'
import DatabaseWidget from './database_widget'
import DownloadWidget from './download_widget'

// need to be placed outside the function Component otherwise it will be redefined on every run cousing a complete remount
const Styles = styled.div`
  .card {
    .card-header {
      padding: 0.5rem;
    }
    .card-body {
      padding: 0.5rem;
    }
    .card-footer {
      padding: 0.5rem;
    }
  }
`

function Visualizer({
  projectId,
  currentView,
  workbook,
  checkedCohorts,
  model,
  onlyRead = false,
  isCurrentViewChangedContent,
  nodeIds,
  refPidType,
  selectedPids,
  handleSelectedPidsChange,
  handleLiftUpHistoryChange,
  forceEncryptionOnExport,
  options = {},
  liftUpHistoryData,
  fieldNameRegexp,
  privileges = {},
}) {
  // console.debug(`rendering visulizer ${model.id}`)

  const [isLoading, setIsLoading] = useState(false)
  const [contents, setContents] = useState([])
  const liftUpHistory = useMemo(() => new LiftUpHistory(liftUpHistoryData || []), [liftUpHistoryData])
  const [selectedSheetId, setSelectedSheetId] = useState('main')

  useEffect(() => {
    setIsLoading(true)
    xFetch(Routes.project_data_visualizer_select_path(projectId, model.id), { query: { cohort_ids: _.join(checkedCohorts) } })
      .then((res) => {
        setIsLoading(false)
        setContents(_.map(res, 'data'))
      })
  }, [checkedCohorts])

  let elabWorkbook = useMemo(
    () => {
      let wb = _.cloneDeep(workbook)

      console.debug(`Visualizer ${model.id}: keep only sheets involved in node selection`)
      // keep only sheets involved in node selection
      wb = _.pickBy(wb, sheet => sheet.id == 'main' || _.some(nodeIds, nodeId => _.startsWith(nodeId, sheet.nodeId)))

      console.debug(`Visualizer ${model.id}: integrate workbook with ids and ancestor keys fields`)
      _.each(wb, (sheet) => {
        sheet.parent = wb[sheet.parentId]
        sheet.children = _.values(_.pick(wb, ...sheet.childIds))
        sheet.ancestors = function () {
          return this.parent ? this.parent.ancestors().concat([this.parent]) : []
        }

        if (sheet.isMain) {
          sheet.idName = options.ids[0]
          sheet.foreignIdName = options.ids[0]
        }

        // remove unselected collector nodes
        _.remove(sheet.fields, f => !_.includes(nodeIds, f.id))

        // before adding derived fields mark all original fields
        _.each(sheet.fields, f => f.type = 'orig')

        let auxFields = []

        // add ids
        _.each(options.ids, (id, i) => {
          if (i == 0) { // add pid which must be present, at least hidden
            auxFields.push({
              id: id,
              name: id,
              contentType: 'string',
              type: 'pId',
              hidden: !(sheet.isMain || options.showPersonIdsInAllSheets),
            })
          }
          else { // add all other person ids
            if (sheet.isMain || options.showPersonIdsInAllSheets) {
              auxFields.push({
                id: id,
                name: id,
                contentType: 'string',
                type: 'pId',
              })
            }
          }
        })

        if (sheet.isMain) {
          // timestamps
          auxFields.push({
            id: 'created_at',
            name: 'created_at',
            contentType: 'string',
            type: 'info',
            hidden: !options.showTimestamps,
          })
          auxFields.push({
            id: 'updated_at',
            name: 'updated_at',
            contentType: 'string',
            type: 'info',
            hidden: !options.showTimestamps,
          })
        }
        else {
          // add ancestors foreign keys
          _.each(sheet.ancestors(), (a) => {
            if (a.isMain) { // ancestor main is just unhide
              auxFields[0].hidden = false
            }
            else {
              auxFields.push({
                id: a.foreignKeyName,
                name: a.foreignKeyName,
                contentType: 'string',
                type: 'fKey',
                hidden: !(a == sheet.parent) && !options.showAllAncestorsKeys, // parent must be present (liftUp application is based on the parent node )
              })
            }
          })

          // add sequential additional ids (liftUp use them to aggregate)
          // sequential foreign key to parent
          if (!sheet.parent.isMain) {
            auxFields.push({
              id: sheet.parent.foreignIdName,
              name: sheet.parent.foreignIdName,
              contentType: 'integer',
              type: 'sfKey',
              hidden: !options.showSequentialIds,
            })
          }
          // sequential primary key
          auxFields.push({
            id: sheet.idName,
            name: sheet.idName,
            contentType: 'integer',
            type: 'spKey',
            hidden: !options.showSequentialIds,
          })
        }

        sheet.fields.unshift(...auxFields)
      })
      return wb
    }, [
      nodeIds,
      options,
    ]
  )

  // integrate contents and assign values to elabWorkbook
  useMemo(
    () => {
      console.debug(`Visualizer ${model.id}: integrate contents with info related to ids and ancestors`)

      // console.time("wholeLoop")
      const integratedContents = _.map(contents, (content) => {
        // console.time("contentLoop")
        let personIdValues = Object.assign({}, ...options.ids.map(id => ({ [id]: content[id] })))
        let integratedContent = _.cloneDeep(content)

        let res = _.reduceDeep(integratedContent, (accumulator, value, key, parentValue, context) => {
          // element belonging to array needs to be integrated with info related to ancestors
          if (_.isArray(parentValue)) {
            // here we need to guess the sheetId from current data value
            // removing all array indexes like "tumors[0].chemotherapies[3].treatments"
            // and chars []" in case of path starting with numbers like '["3elr_postoperativeComplications"]'
            let sheetId = context.parent.path.replaceAll(/\[\d+\]/g, '').replaceAll(/[\[\]"]/g, ''),
              sheet = elabWorkbook[sheetId]

            if (sheet) {
              let newValue = _.cloneDeep(value)

              // if it is an array of scalar the sheet is integrated with value filed
              if (!_.isPlainObject(value)) {
                newValue = { value: value }
              }

              // set all ancestor key values
              _.forEach(sheet.ancestors(), (currentAncestor) => {
                if (currentAncestor.isMain) return // skip main sheet foreign key as it's  always added by adding personIdValues
                let currentAncestorValue = context.parents.find(a => a.parent && a.parent.key == currentAncestor.name).value
                newValue[currentAncestor.foreignKeyName] = currentAncestorValue[currentAncestor.keyName]
              })

              sheet.counter ? sheet.counter += 1 : sheet.counter = 1

              // set sequential row id
              newValue[sheet.idName] = sheet.counter

              // set parent row id
              if (!sheet.parent.isMain) {
                newValue[sheet.parent.foreignIdName] = sheet.parent.counter
              }

              // integrate with personIdValues
              _.merge(newValue, personIdValues)
              _.set(accumulator, context.path, newValue)
            }
          }

          return accumulator
        }, integratedContent, { includeRoot: false })
        // console.timeEnd("contentLoop")
        return res
      })
      // console.timeEnd("wholeLoop")
      elabWorkbook.main.counter = integratedContents.length

      console.debug(`Visualizer ${model.id}: add values to elabWorkbook`)
      _.each(elabWorkbook, (sheet, sheetId) => {
        if (integratedContents.length) {
          // select current sheet contents
          let sheetContents, path = sheetId.split('.')

          if (path == 'main') {
            sheetContents = integratedContents
          }
          else {
            do {
              let currentPath = path.shift()
              sheetContents = _.flatten(_.compact(_.map(sheetContents || integratedContents, c => c[currentPath])))
            } while (path.length)
          }

          // select only fields involved by the current sheet
          sheet.values = _.map(sheetContents, c => _.reduce(sheet.fields, (memo, f) => {
            memo[f.name.replaceAll('.', '_')] = _.get(c, f.name)
            if (_.isArray(memo[f.name])) {
              memo[f.name] = memo[f.name].join(', ')
              console.warn(`An unexpected array was found in field ${f.name} in sheet ${sheet.id}. It's elements will be joined for visualization in sheet cell`)
            }
            return memo
          }, {}))

          sheet.pIds = _.map(sheet.values, refPidType).sort()

          // consolidate columns types
          sheet.fields = _.map(sheet.fields, (f) => {
            const values = _.without(_.map(sheet.values, v => v[f.name]), '', undefined, null, NaN),
              detectedTypes = _.uniq(values.map(v => typeof (v))),
              declaredType = f.contentType

            let resultingType

            switch (detectedTypes.length) {
              case 0:
                console.warn(`Cannot detect type for column ${f.name} in sheet ${sheet.id}: no data found. DeclaredType (${declaredType}) will be used`)
                resultingType = declaredType == 'number' ? 'integer' : declaredType // fallback to the type coming from the sjs model
                break
              case 1:
                if (detectedTypes[0] == 'number') {
                  resultingType = _.some(values, v => !_.isInteger(v)) ? 'float' : 'integer'
                }
                else {
                  resultingType = detectedTypes[0]
                }
                break
              default:
                console.warn(`Cannot determine type for column ${f.name} in sheet ${sheet.id}: different types detected (${detectedTypes.join(',')})`)
                resultingType = undefined
                break
            }

            if (resultingType == 'object') { // this also includes array because typeof([]) return object
              console.warn(`Detected type is not a scalar (string,number,boolean) for column ${f.name} in sheet ${sheet.id}`)
            }

            if (resultingType && declaredType && declaredType != resultingType) {
              console.warn(`Detected type (${resultingType}) is different from declared type (${declaredType}) for column ${f.name} in sheet ${sheet.id}`)
            }

            f.contentType = resultingType

            if (values.length) {
              switch (resultingType) {
                case 'integer':
                  f.maxVal = Math.max(...values)
                  break
                case 'float':
                  f.maxVal = Math.max(...values)
                  break
                case 'string':
                  f.maxLength = Math.max(...(values.map(el => el.length)))
                  break
              }
            }

            return f
          })
        }
        else {
          sheet.values = []
        }
      })
    }, [
      elabWorkbook,
      contents.length,
    ]
  )

  // apply liftup history to elabWorkbook
  useMemo(
    () => {
      console.debug(`Visualizer ${model.id}: applying liftup history`)
      // remove all liftedUp fields
      _.values(elabWorkbook).forEach(sheet => _.remove(sheet.fields, f => f.type == 'liftedUp'))
      if (elabWorkbook.main.counter) {
        // rebuild all liftedUp fields
        _.each(liftUpHistory.steps, (step, stepIdx) => {
          let
            sheet = elabWorkbook[step.sheetId],
            sourceField

          // organize parent values by id
          const parentSheetValuesById = _.keyBy(sheet.parent.values, sheet.parent.idName)
          const sheetValuesByParentId = _.groupBy(sheet.values, v => v[sheet.parent.foreignIdName])

          let insertAt = step.afterField
            ? step.afterField == 'at the beginning'
              ? sheet.parent.fields.findIndex(f => f.type == 'orig')
              : sheet.parent.fields.findIndex(f => f.name == step.afterField) + 1
            : sheet.parent.fields.length

          let contentType

          switch (step.type) {
            case 'agg':

              sourceField = _.find(sheet.fields, f => f.name == step.field)
              contentType = sourceField.contentType
              _.each(sheetValuesByParentId, (items, id) => {
                const values = _.compact(_.map(items, step.field))
                if (_.isEmpty(values)) {
                  return
                }
                switch (step.formula) {
                  case 'concat':
                    parentSheetValuesById[id][step.name] = (sheet.isMadeOfScalars ? values.sort() : values).join(' | ') // sort values in case of scalars
                    contentType = 'string'
                    break
                  case 'count':
                    parentSheetValuesById[id][step.name] = values.length
                    contentType = 'integer'
                    break
                  case 'sum':
                    parentSheetValuesById[id][step.name] = _.sum(values)
                    break
                  case 'min':
                    parentSheetValuesById[id][step.name] = _.min(values)
                    break
                  case 'max':
                    parentSheetValuesById[id][step.name] = _.max(values)
                    break
                  case 'avg':
                    parentSheetValuesById[id][step.name] = _.mean(values)
                    contentType = 'float'
                    break
                }
              })
              sheet.parent.fields.splice(insertAt, 0, {
                name: step.name,
                contentType: contentType,
                type: 'liftedUp',
                liftedUpStep: stepIdx,
              })
              break
            case 'exp':
              _.times(_.max(_.map(_.values(sheetValuesByParentId), 'length')), i =>
                _.each(step.fields, (fieldName) => {
                  const name = `${step.prefix}${sheet.isMadeOfScalars ? '' : fieldName.concat('_')}${i + 1}`
                  sourceField = _.find(sheet.fields, f => f.name == fieldName)
                  sheet.parent.fields.splice(insertAt, 0, {
                    name: name,
                    contentType: sourceField.contentType,
                    type: 'liftedUp',
                    liftedUpStep: stepIdx,
                  })
                  insertAt += 1
                })
              )
              _.each(sheetValuesByParentId, (items, id) => {
                _.each(sheet.isMadeOfScalars ? _.sortBy(items, 'value') : items, (item, i) => { // sort values in case of scalars
                  _.each(step.fields, (f) => {
                    const name = `${step.prefix}${sheet.isMadeOfScalars ? '' : f.concat('_')}${i + 1}`
                    parentSheetValuesById[id][name] = item[f] // do not compress in one line code because item[step.field] may be false in case of boolean field and break _.each loop
                  })
                })
              })
              break
          }
        })
      }
    }, [
      elabWorkbook,
      contents.length,
      liftUpHistoryData,
    ]
  )

  // update selectedCounter
  useMemo(
    () => {
      console.debug(`Visualizer ${model.id}: update selected counters`)
      _.each(_.values(elabWorkbook), sheet => sheet.selectedCounter = selectedPids ? _.filter(sheet.values, row => _.includes(selectedPids, row[refPidType])).length : undefined)
    }, [
      selectedPids,
      contents.length,
      options,
    ]
  )

  // eslint-disable-next-line no-console
  console.log('->', elabWorkbook)

  const handleGoToContent = useCallback(pId => window.open(Routes.edit_project_person_form_path(projectId, pId, model.id), '_blank'), [projectId, model.id])

  const exportParams = {
    collector_id: model.id,
    node_ids: nodeIds,
    options: options,
    cohort_ids: checkedCohorts,
    selected_pids: selectedPids,
    liftup_history_data: liftUpHistoryData,
  }

  const exportButtons = (
    <div className="d-flex justify-content-between">
      {
        privileges.createExportDbs
          ? currentView && !isCurrentViewChangedContent
            ? (
                <DatabaseWidget
                  canManage={privileges.manageExportDbs}
                  className="float-left"
                  collectorId={model.id}
                  currentView={currentView}
                  exportParameters={exportParams}
                  projectId={projectId}
                  workbook={elabWorkbook}
                />
              )
            : (
                <span className="btn btn-secondary btn-sm disabled">
                  <i className="fa fa-exclamation-circle" />
                  {' '}
                  select a saved view to export workbook as database
                </span>
              )
          : null
      }
      <span className="text-warning">
        <i className="fa fa-exclamation-triangle mr-2" />
        {
          selectedPids
            ? selectedPids.length
              ? `Only the selected records will be exported.`
              : 'Nothing to export. Disable selection to export all records or select some.'
            : 'All records will be exported. Enable selection to export only some of them.'
        }
      </span>
      <DownloadWidget
        collector={model}
        refPidType={refPidType}
        selectedPids={selectedPids}
        selectedSheetId={selectedSheetId}
        workbook={elabWorkbook}
        forceEncryption={forceEncryptionOnExport}
      />
    </div>
  )

  return (
    <Styles>
      <Card bsPrefix="card card-dark border-dark visualizer">
        <Card.Header>
          <Card.Title>
            <strong>{model.title}</strong>
          </Card.Title>
          <div className="card-tools">
            <button className="btn btn-tool" data-card-widget="maximize" type="button">
              <i className="fas fa-expand" />
            </button>
            <button className="btn btn-tool" data-card-widget="collapse" type="button">
              <i className="fas fa-minus" />
            </button>
          </div>
        </Card.Header>
        <Card.Body>
          <WithSpinner isLoading={isLoading}>
            <Workbook
              {...{ onlyRead, refPidType, selectedPids }}
              handleGoToContent={handleGoToContent}
              handleSelectedPidsChange={handleSelectedPidsChange}
              selectedSheetId={selectedSheetId}
              setSelectedSheetId={setSelectedSheetId}
              workbook={elabWorkbook}
            />
            {
              _.keys(elabWorkbook).length > 1
                ? (
                    <LiftUpManager
                      disabled={isLoading || onlyRead}
                      fieldNameRegexp={fieldNameRegexp}
                      handleLiftUpHistoryChange={newLiftUpHistory => handleLiftUpHistoryChange({
                        liftUpHistory: newLiftUpHistory,
                        cId: model.id,
                      })}
                      liftUpHistory={liftUpHistory}
                      workbook={elabWorkbook}
                    />
                  )
                : null
            }
          </WithSpinner>
        </Card.Body>
        <Card.Footer>
          {
            isLoading
              ? null
              : exportButtons
          }
        </Card.Footer>
      </Card>
    </Styles>
  )
}

Visualizer.propTypes = {
  projectId: PropTypes.number,
  currentView: PropTypes.object,
  workbook: PropTypes.object.isRequired,
  checkedCohorts: PropTypes.array,
  model: PropTypes.object.isRequired,
  onlyRead: PropTypes.bool,
  forceEncryptionOnExport: PropTypes.bool,
  isCurrentViewChangedContent: PropTypes.bool,
  nodeIds: PropTypes.array.isRequired,
  refPidType: PropTypes.string.isRequired,
  selectedPids: PropTypes.array,
  handleSelectedPidsChange: PropTypes.func.isRequired,
  handleLiftUpHistoryChange: PropTypes.func.isRequired,
  options: PropTypes.object,
  liftUpHistoryData: PropTypes.array,
  fieldNameRegexp: PropTypes.instanceOf(RegExp).isRequired,
  privileges: PropTypes.object.isRequired,
}

// function arePropsEqual(prevProps, nextProps) {
//   return prevProps.nodeIds === nextProps.nodeIds
//     && prevProps.checkedCohorts === nextProps.checkedCohorts
//     && prevProps.ids === nextProps.ids
//     && prevProps.viewId === nextProps.viewId
//     && _.isEqual(prevProps.selectedPids, nextProps.selectedPids)
//     && _.isEqual(prevProps.liftUpHistoryData, nextProps.liftUpHistoryData)
//     && _.isEqual(prevProps.options, nextProps.options)
//     && _.isEqual(prevProps.currentView, nextProps.currentView)
// }

// const memoizedVisualizer = React.memo(Visualizer, arePropsEqual)
// memoizedVisualizer.displayName = 'Visualizer'

// export default memoizedVisualizer
export default memo(Visualizer)
