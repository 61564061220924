class LiftUpHistoryStep {
  constructor(data, idx, history) {
    this.history = history
    this.idx = idx
    this.data = data
    _.each(data, (v, k) => this[k] = v)
  }

  get parentSheetId() {
    return this.sheetId.split('.').slice(0, -1).join('.')
  }

  get dataPaths() {
    return _.map(_.flatten(_.compact([this.field, this.fields])), f => [this.sheetId, f].join('.'))
  }

  hasAfterFieldDerivedFrom(stepToBeRemoved) {
    switch (stepToBeRemoved.type) {
      case 'agg':
        return stepToBeRemoved.name == this.afterField
      case 'exp':
        return _.some(stepToBeRemoved.fields, f => _.startsWith(this.afterField, stepToBeRemoved.prefix + f + '_'))
      default:
        console.error(`unexpected liftup history step type ${stepToBeRemoved.type}`)
        return false
    }
  }

  get afterFieldDataPath() {
    if (!this.afterField) return undefined
    let sheetIdArray = this.sheetId.split('.')
    if (sheetIdArray.length == 1) {
      return this.afterField
    }
    else {
      return [this.parentSheetId, this.afterField].join('.')
    }
  }

  isInUse() {
    return _.some(_.slice(this.history.steps, this.idx + 1), (laterStep) => {
      if (laterStep.sheetId == this.parentSheetId) { // laterStep field may be taken from the field produced by this step
        switch ([this.type, laterStep.type].join('')) {
          case 'aggagg':
            return laterStep.field == this.name
          case 'aggexp':
            return _.some(laterStep.fields, f => f == this.name)
          case 'expagg':
            return _.some(this.fields, f => _.startsWith(laterStep.field, `${this.prefix}${f}`))
          case 'expexp':
            return _.some(laterStep.fields, lhsf => _.some(this.fields, sf => _.startsWith(lhsf, `${this.prefix}${sf}`)))
        }
      }
      else {
        return false
      }
    })
  }
}
export default class LiftUpHistory {
  constructor(initialData = []) {
    this.initialData = initialData
    this.steps = _.map(initialData, (rawStep, i) => new LiftUpHistoryStep(rawStep, i, this))
  }

  addStep(data) {
    this.steps.push(new LiftUpHistoryStep(data))
  }

  replaceStepAt(idx, data) {
    this.steps[idx] = new LiftUpHistoryStep(data)
  }

  removeStepAt(idx) {
    let stepToBeRemoved = this.steps[idx]
    _.each(_.slice(this.steps, idx + 1), (s) => {
      if (s.afterField && s.hasAfterFieldDerivedFrom(stepToBeRemoved)) {
        this.replaceStepAt(s.idx, { ...s.data, afterField: null })
      }
    })
    this.steps.splice(idx, 1)
  }

  get data() {
    return _.map(this.steps, 'data')
  }

  get involvedDataPaths() {
    return _.uniq(_.compact(_.flattenDeep([_.map(this.steps, 'dataPaths'), _.map(this.steps, 'afterFieldDataPath')])))
  }
}
