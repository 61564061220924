import React, { useRef, useEffect, useState } from 'react'
import { createRoot } from 'react-dom/client'
import PropTypes from 'prop-types'
import * as showdown from 'showdown'
import { OverlayTrigger, Tooltip, Badge, Popover, Overlay, Button } from 'react-bootstrap'
import { create } from 'jsondiffpatch'
import { CopyToClipboard } from 'react-copy-to-clipboard'

export const mountReactComponent = (component, componentName) => {
  const nodes = Array.from(
    document.getElementsByClassName(`react-${componentName}`)
  )

  nodes.forEach((node) => {
    const data = node.getAttribute('data')
    const props = data && data.length > 2 ? JSON.parse(data) : {}
    // console.debug(`Render react component ${componentName} with props: ${JSON.stringify(props,null,2)}`)
    console.debug(`Render react component ${componentName} with props:`, props)
    const el = React.createElement(component, { ...props }, [])
    node.innerHTML = ''
    const root = createRoot(node)
    root.render(el)
    // root.render(
    //   <StrictMode>
    //     {el}
    //   </StrictMode>
    // )
  })
}

export const usertimestampedBy = function (time, by, prefix) {
  return (
    <span>
      {prefix}
      {' '}
      at
      {' '}
      <i>{moment(time).format('l LT')}</i>
      {' '}
      by
      {' '}
      <b>{by}</b>
    </span>
  )
}

var
  converter = new showdown.Converter(),
  markdownConverterResults = {}

export const md2Html = function (mdText) {
  let str = markdownConverterResults[mdText]
  if (!str) {
    // convert the markdown text to html
    str = converter.makeHtml(mdText)
    // remove root paragraphs <p></p>
    str = str.replace(/^<p>/, '')
    str = str.replace(/<\/p>$/, '')
    markdownConverterResults[mdText] = str
  }
  return str
}

export const markdownHandler = function (survey, options) {
  if (options.name == 'text') return // skip property named 'text' in order to speed up rendering process
  options.html = md2Html(options.text)
}

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON, status from the response
 */
function parseJSON(response) {
  return new Promise((resolve, reject) => {
    response
      .text()
      .then((text) => {
        try {
          resolve({
            response: response,
            json: text.length ? JSON.parse(text) : {},
          })
        }
        catch (err) {
          reject(err)
        }
      })
  })
}

/**
 * XFetch: an Enhanced fetch for errors management
 * based on https://github.com/github/fetch/issues/203#issuecomment-266034180
 * It does not show flash messages. Error messages should be generated by client based on response status and/or body
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {Promise}           The request promise
 */
export const xFetch = function (url, options = {}) {
  return new Promise((resolve, reject) => {
    let defaultHeaders = {
      'X-CSRF-Token': window.csrfToken,
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }
    options.headers = Object.assign(defaultHeaders, options.headers)
    options.method = _.upperCase(options.method || 'GET') // fetch method is casesensitive!
    if (options.method === 'GET' && options.query) {
      url = `${url}?${new URLSearchParams(options.query)}`
    }
    fetch(url, options)
      .then(parseJSON)
      .then((res) => {
        if (res.response.ok) {
          resolve(res.json)
          return
        }
        // extract the errors from the server's json
        let errors = [res.json.error || res.json.errors].flat().filter(v => v)
        console.error('xFetch - server error:', res.response.status, errors)

        const flash = res.response.headers.get('X-Flash')
        flash && showFlashMessages(JSON.parse(flash))

        reject({ msgs: errors, status: res.response.status })
      })
      .catch((error) => {
        if (error.name == 'AbortError') {
          reject({ msgs: ['xFetch request was kindly aborted'] })
        }
        else {
          // any other unexpected errors
          console.error('xFetch - unexpected error:', error.message)
          reject({ msgs: [error.message] })
        }
      })
  })
}

let sessionTimeoutIn
if (document.body.dataset.sessionTimeoutIn) {
  sessionTimeoutIn = Number(document.body.dataset.sessionTimeoutIn)
}
else {
  console.error('document.body.dataset.sessionTimeoutIn is undefined. Fallback to 5 min')
  sessionTimeoutIn = 5
}
export const keepSessionAliveThrottleTimeInSecs = sessionTimeoutIn * 60 / 3 // 1/3 of devise session timeout_in
export const keepSessionAlive = _.throttle(() => fetch(Routes.keep_session_alive_path()), keepSessionAliveThrottleTimeInSecs * 1000)

export const runPromisesSequentially = (arrayOfFunctionsReturningAPromise = []) =>
  arrayOfFunctionsReturningAPromise.reduce(
    (previousPromise, promise) => {
      return previousPromise.then(promise)
    },
    Promise.resolve(null)
  )

export function Userstamp({
  created_at,
  updated_at,
  creator,
  updater,
}) {
  return created_at && updated_at
    ? (
        <div className="row text-gray-500">
          <div className="col-6">
            <Badge variant="secondary">C</Badge>
            <OverlayTrigger
              overlay={(
                <Tooltip id="labelTooltip">
                  Created at
                  {' '}
                  {moment(created_at).format('l LT')}
                  {creator ? ` by ${creator}` : null}
                </Tooltip>
              )}
              placement="top"
            >
              <span>{moment(created_at).format('l LT')}</span>
            </OverlayTrigger>
          </div>
          <div className="col-6 text-right">
            <Badge variant="secondary">U</Badge>
            <OverlayTrigger
              overlay={(
                <Tooltip id="labelTooltip">
                  Updated at
                  {' '}
                  {moment(updated_at).format('l LT')}
                  {updater ? ` by ${updater}` : null}
                </Tooltip>
              )}
              placement="top"
            >
              <span>{moment(updated_at).format('l LT')}</span>
            </OverlayTrigger>
          </div>
        </div>
      )
    : null
}
Userstamp.propTypes = {
  created_at: PropTypes.string,
  creator: PropTypes.string,
  updated_at: PropTypes.string,
  updater: PropTypes.string,
}

export function WithSpinner({
  isLoading = false,
  children,
}) {
  return (
    <div className="with-spinner-wrapper">
      {
        isLoading
          ? (
              <div className="loader">
                <div className="d-flex h-100 align-items-center justify-content-center">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            )
          : null
      }
      {children}
    </div>
  )
}
WithSpinner.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.any,
}

export function HelpPopper({
  children,
  options,
}) {
  const popover = (
    <Popover>
      {
        options?.title ? <Popover.Title as="h3">{options.title}</Popover.Title> : null
      }
      <Popover.Content>
        {children}
      </Popover.Content>
    </Popover>
  )

  return (
    <OverlayTrigger overlay={popover} placement={options?.placement || 'auto'} rootClose trigger="click">
      <i className="ml-1 fas fa-question-circle" />
    </OverlayTrigger>
  )
}

HelpPopper.propTypes = {
  options: PropTypes.object,
  children: PropTypes.any,
}

export const bootstrapSelectStyle = {
  control: (provided, state) => ({
    ...provided,
    'borderColor': state.selectProps.validated && state.selectProps.isInvalid ? 'red' : provided.borderColor,
    '&:hover': {
      borderColor: state.selectProps.validated && state.selectProps.isInvalid ? 'red' : provided['&:hover'].borderColor,
    },
  }),
  multiValue: (provided, state) => ({
    ...provided,
    color: 'white',
    border: '1px solid #006fe6',
    borderRadius: '4px',
    backgroundColor: '#007bff',
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: 'white',
  }),
  menuPortal: (provided, state) => ({
    ...provided,
    zIndex: 2000,
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    'color': 'rgba(255, 255, 255, 0.7)',
    '&:hover': {
      backgroundColor: 'inherit',
      color: 'white',
    },
  }),

}

export function useFirstRender() {
  const firstRender = useRef(true)

  useEffect(() => {
    firstRender.current = false
  }, [])

  return firstRender.current
}

// create a configured instance, match objects by id
const diffpatcher = create({
  objectHash: function (obj) {
    return obj.id
  },
})

export const addDeletionsToDataForRails = (data, initialData) => {
  const _isAnAssociationField = fieldName => _.endsWith(fieldName, '_attributes')
  const dataToSend = _.cloneDeep(data)

  // online diffpatcher https://benjamine.github.io/jsondiffpatch/demo/index.html
  let differences = diffpatcher.diff(initialData, data)
  // console.debug(initialData);
  // console.debug(data);
  // console.debug(differences);

  _.forOwn(differences, (value, fieldName) => {
    if (_isAnAssociationField(fieldName)) { // it's a nested association
      if (value._t == 'a') { // differences involve the array elements which means one or more associations
        _.forOwn(_.omitBy(value, (v, k) => k == '_t'), (v, k) => { // for all position differences
          if (_.startsWith(k, '_')) { // the association has been dropped
            if (v[1] == 0) {
              dataToSend[fieldName].push({ _destroy: 1, id: v[0].id })
            }
          }
          else {
            if (!_.isArray(v)) { // the association has been modified
              let i = parseInt(k)
              _.forOwn(v, (v, attr) => {
                if (_.isEqual(v.slice(1), [0, 0])) {
                  dataToSend[fieldName][i][attr] = null
                }
              })
            }
          }
        })
      }
      else {
        if (value[1] == 0) { // all associations were dropped
          dataToSend[fieldName] = []
          _.forEach(value[0], (v) => {
            dataToSend[fieldName].push({ _destroy: 1, id: v.id })
          })
        }
      }
    }
    else {
      if (value._t != 'a' && _.isEqual(value.slice(1), [0, 0])) { // it's a deletion not in an array
        let oldValue = value[0]
        let newValue

        if (_.isArray(oldValue)) {
          newValue = []
        }
        else {
          newValue = null
        }
        dataToSend[fieldName] = newValue
      }
    }
  })

  // console.debug('survey data with deletions:');
  // console.debug(JSON.stringify(dataToSend,null,2));

  return dataToSend
}

export function CopyToClipboardButton({
  children,
  text,
  ...buttonProps
}) {
  const [showCopied, setShowCopied] = useState(false)
  const target = useRef(null)

  useEffect(() => {
    // Use setTimeout to update the message after 2000 milliseconds (2 seconds)
    const timeoutId = setTimeout(() => {
      setShowCopied(false)
    }, 2000)

    // Cleanup function to clear the timeout if the component unmounts
    return () => clearTimeout(timeoutId)
  }, [showCopied])

  return (
    <>
      <CopyToClipboard
        onCopy={() => setShowCopied(true)}
        text={text}
      >
        {
          children
            ? children(target)
            : (
                <Button
                  ref={target}
                  title="copy to clipboard"
                  {...buttonProps}
                >
                  <i className="fas fa-copy" />
                </Button>
              )
        }
      </CopyToClipboard>
      <Overlay placement="top" show={showCopied} target={target.current}>
        <Tooltip>
          copied!
        </Tooltip>
      </Overlay>

    </>
  )
}
