import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import Sheet from './sheet'
import { Form, OverlayTrigger, Tooltip, ToggleButtonGroup, ToggleButton, Badge, Dropdown, DropdownButton } from 'react-bootstrap'
import styled from 'styled-components'
import TreeMenu, { ItemComponent } from 'react-simple-tree-menu'
import { WithSpinner, HelpPopper } from '../../utils'

// need to be placed outside the function Component otherwise it will be redefined on every run cousing a complete remount
const Styles = styled.div`
`

export default function Workbook({
  refPidType,
  workbook,
  selectedPids,
  handleSelectedPidsChange,
  onlyRead,
  handleGoToContent,
  selectedSheetId,
  setSelectedSheetId,
}) {
  console.debug('rendering Workbook')

  const [workbookState, setWorkbookState] = useState({})
  const [sheetsNavMode, setSheetsNavMode] = useState('path')
  const [showOnlySelectedPids, setShowOnlySelectedPids] = useState(false)
  const [filtersEnabled, setFiltersEnabled] = useState(false)

  const selectedSheet = workbook[selectedSheetId]

  if (!selectedSheet) {
    setSelectedSheetId('main') // fallback to main sheet when selected sheet is not present in workbook anymore
  }

  // reset all sheets to the first page when showOnlySelectedPids changes
  React.useEffect(() => {
    _.forEach(workbookState, sheetState => sheetState.pagination = { ...sheetState.pagination, pageIndex: 0 })
  }, [showOnlySelectedPids])

  let sheetsNavData
  switch (sheetsNavMode) {
    case 'path':
      sheetsNavData = _.map(workbook, (s, sId) => {
        return {
          key: sId,
          label: sId,
          selectedCounter: s.selectedCounter,
          counter: s.counter,
        }
      })
      break
    case 'tree':
      sheetsNavData = [_.mapKeysDeep(_.mapValuesDeep(workbook['main'], s => ({ ..._.pick(s, ['id', 'name', 'children', 'counter', 'selectedCounter']) }), { childrenPath: 'children' }), (v, k) => {
        switch (k) {
          case 'id':
            return 'key'
          case 'name':
            return 'label'
          case 'children':
            return 'nodes'
          default:
            return k
        }
      })]
      break
    case 'excel':
      sheetsNavData = _.map(workbook, (s, sId) => {
        return {
          key: sId,
          label: s.excelName,
          selectedCounter: s.selectedCounter,
          counter: s.counter,
        }
      })
      break
    case 'db':
      sheetsNavData = _.map(workbook, (s, sId) => {
        return {
          key: sId,
          label: s.tableName,
          selectedCounter: s.selectedCounter,
          counter: s.counter,
        }
      })
      break
  }

  const handleWorkbookStateChange = useCallback((newState, sheetId) => {
    setWorkbookState({ ...workbookState, [selectedSheetId]: newState })
  }, [selectedSheetId, workbookState])

  const resetAllSheets = () => {
    setWorkbookState({})
  }

  const resetSelectedSheet = () => {
    setWorkbookState({ ...workbookState, [selectedSheetId]: undefined })
  }

  const sheetTabLabel = function (sheetId, selectedCounter, counter) {
    return (
      <>
        <span className="mr-1">
          <i>{sheetId}</i>
        </span>
        {
          selectedCounter
            ? (
              <Badge pill variant="success">
                {selectedCounter}
              </Badge>
              )
            : null
        }
        {
          filtersEnabled && !_.isEmpty(workbookState[sheetId]?.columnFilters) && workbookState[sheetId]?.filteredRowsCount
            ? (
              <Badge pill variant="info">
                {
                  workbookState[sheetId]?.filteredRowsCount
                }
              </Badge>
              )
            : null
        }
        {
          !showOnlySelectedPids
            ? (
              <Badge pill variant="secondary">
                {counter}
              </Badge>
              )
            : null
        }
      </>
    )
  }

  const sheetToolbar = (
    <div className="d-flex justify-content-between mb-1">
      <Form>
        {
          selectedPids
            ? (
              <Form.Check
                checked={showOnlySelectedPids}
                id="showOnlySelected"
                inline
                label="show selected only"
                onChange={e => setShowOnlySelectedPids(e.target.checked)}
                type="checkbox"
              />
              )
            : null
        }
        <Form.Check
          bsPrefix="form-check d-none"
          checked={filtersEnabled}
          id="filtersEnabled"
          inline
          label="show filters"
          onChange={e => setFiltersEnabled(e.target.checked)}
          type="checkbox"
        />
      </Form>
      <div>
        <DropdownButton
          className="d-inline"
          size="xs"
          title={(
            <span>
              <i className="fas fa-undo mr-1" />
              Reset
            </span>
          )}
          variant="outline-secondary"
        >
          <Dropdown.Item
            disabled={!workbookState[selectedSheetId]}
            key="current"
            onClick={resetSelectedSheet}
          >
            current sheet
          </Dropdown.Item>
          <Dropdown.Item
            disabled={_.isEmpty(workbookState)}
            key="all"
            onClick={resetAllSheets}
          >
            all sheets
          </Dropdown.Item>
        </DropdownButton>
        <HelpPopper>
          Reset pagination and sorting
        </HelpPopper>
      </div>
    </div>
  )

  return (
    <Styles>
      <WithSpinner isLoading={false}>

        {
          selectedSheet
            ? (
              <>
                {sheetToolbar}
                <Sheet
                  {..._.pick(selectedSheet, ['keyName', 'values'])}
                  disableRowSelection={onlyRead}
                  fields={[...selectedSheet.fields]} // fields array content may change: we need to deep duplicate otherwise the component will not rerender
                  filtersEnabled={filtersEnabled}
                  handleGoToContent={handleGoToContent}
                  handleSelectedPidsChange={handleSelectedPidsChange}
                  handleSheetStateChange={handleWorkbookStateChange}
                  key={`${selectedSheetId}-${!workbookState[selectedSheetId]}`}
                  refPidType={refPidType}
                  selectedPids={selectedPids}
                  sheetState={workbookState[selectedSheetId]}
                  showOnlySelectedPids={showOnlySelectedPids}
                />
                <TreeMenu
                  activeKey={sheetsNavMode == 'tree'
                    ? selectedSheetId == 'main' ? 'main' : _.compact(['main', ...selectedSheetId.split('.').slice(0, -1), selectedSheetId]).join('/')
                    : selectedSheetId}
                  data={sheetsNavData}
                  disableKeyboard="true"
                  hasSearch={false}
                  onClickItem={item => setSelectedSheetId(item.key.split('/')[item.level])}
                >
                  {({ items }) => (
                    <ul className={`rstm-tree-item-group sheets-nav-mode-${sheetsNavMode == 'tree' ? 'tree' : 'flat'}`}>
                      {items.map(({ key, label, selectedCounter, counter, ...props }) => (
                        <ItemComponent key={key} {...props} label={sheetTabLabel(label, selectedCounter, counter)} />
                      ))}
                    </ul>
                  )}
                </TreeMenu>
              </>
              )
            : null
        }
        <div className="d-flex justify-content-between">
          <OverlayTrigger
            overlay={(
              <Tooltip id="tooltip-tab-nav-mode">
                Sheets navigation mode
              </Tooltip>
            )}
            placement="right"
          >
            <ToggleButtonGroup
              className="mb-3"
              name="tab-nav-mode"
              onChange={
              (v) => {
                setSheetsNavMode(v)
              }
            }
              size="sm"
              type="radio"
              value={sheetsNavMode}
            >
              <ToggleButton value="path" variant="outline-secondary">path</ToggleButton>
              <ToggleButton value="tree" variant="outline-secondary">tree</ToggleButton>
              <ToggleButton value="excel" variant="outline-secondary">excel</ToggleButton>
              <ToggleButton value="db" variant="outline-secondary">db</ToggleButton>
            </ToggleButtonGroup>
          </OverlayTrigger>
        </div>

      </WithSpinner>
    </Styles>
  )
}
Workbook.propTypes = {
  refPidType: PropTypes.string.isRequired,
  handleGoToContent: PropTypes.func.isRequired,
  handleSelectedPidsChange: PropTypes.func.isRequired,
  workbook: PropTypes.object.isRequired,
  selectedPids: PropTypes.array,
  onlyRead: PropTypes.bool,
}
