import React from 'react'
import PropTypes from 'prop-types'
import Table from './table'
import { xFetch } from '../utils'

const buildUrl = ({ path, pageSize, pageIndex, sortBy, filters }) => {
  let url = new URL(window.location.origin + path)
  let query = new URLSearchParams()

  if (sortBy) {
    query.append('sort[by]', sortBy.id)
    query.append('sort[dir]', sortBy.desc ? 'desc' : 'asc')
  }

  if (filters) {
    for (var f of filters) {
      query.append(`filter[${f.id}]`, f.value)
    }
  }

  query.append('page[size]', pageSize)
  query.append('page[current]', pageIndex)

  url.search = query

  return url
}

export default function ResourcesLister({
  actionCell,
  attributes,
  itemsPath,
  rowId,
  initialSorting,
  downloadEnabled,
}) {
  const [data, setData] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [pageCount, setPageCount] = React.useState(0)
  const [totResults, setTotResults] = React.useState(0)
  const fetchIdRef = React.useRef(0)

  // console.log(data,loading,pageCount,totResults)
  let previousRequestController

  const fetchData = React.useCallback(({ pageSize, pageIndex, sorting, columnFilters }) => {
    previousRequestController && previousRequestController.abort()
    setLoading(true)
    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current
    previousRequestController = new AbortController()
    fetchId === fetchIdRef.current
    && xFetch(buildUrl({
      path: itemsPath,
      pageSize: pageSize,
      pageIndex: pageIndex,
      sortBy: sorting && sorting[0],
      filters: columnFilters,
    }), { signal: previousRequestController.signal }).then((res) => {
      setData(res.rows)
      setTotResults(res.totResults)
      setPageCount(Math.ceil(res.totResults / pageSize))
      previousRequestController = undefined
      setLoading(false) // must be placed at the end!
    })
  }, [])

  return (
    <Table
      actionCell={actionCell}
      attributes={attributes}
      data={data}
      downloadEnabled={downloadEnabled}
      fetchData={fetchData}
      initialSorting={initialSorting}
      loading={loading}
      onClickRow={
          (e, rowData) => e.metaKey
            ? window.open(rowData.path, '_blank')
            : window.location = rowData.path
        }
      pageCount={pageCount}
      rowId={rowId}
      totResults={totResults}
    />
  )
}

ResourcesLister.propTypes = {
  actionCell: PropTypes.func,
  attributes: PropTypes.object.isRequired,
  itemsPath: PropTypes.string.isRequired,
  rowCount: PropTypes.number,
  rowId: PropTypes.string,
  initialSorting: PropTypes.array,
  downloadEnabled: PropTypes.bool,
}
