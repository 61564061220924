import React, { memo, useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import CheckboxTree from 'react-checkbox-tree'
import { Card, Row, Col, Tooltip, Form, OverlayTrigger, InputGroup, Button } from 'react-bootstrap'
import styled from 'styled-components'
import LiftUpHistory from './liftup_history'
import LoadingOverlay from 'react-loading-overlay'
LoadingOverlay.propTypes = undefined // workaround to remove warning https://github.com/derrickpelletier/react-loading-overlay/pull/57#issuecomment-1054194254

function CheckBoxLabel({
  data_type,
  title,
  name,
  is_key,
  titleMode,
}) {
  const displayName = titleMode ? title : name
  const tooltip
    = (
      <Tooltip className={title == name ? 'hidden' : null} id="tooltip1">
        {titleMode ? name : title}
      </Tooltip>
    )

  return (
    <OverlayTrigger overlay={tooltip} placement="top">
      <span>
        {/^array_of_/.test(data_type) ? `[${displayName}]` : displayName}
        {
        is_key ? <sup className="text-danger">KEY</sup> : ''
      }
      </span>
    </OverlayTrigger>
  )
}

CheckBoxLabel.propTypes = {
  data_type: PropTypes.string,
  title: PropTypes.string,
  name: PropTypes.string.isRequired,
  is_key: PropTypes.bool,
  titleMode: PropTypes.bool,
}

// need to be placed outside the function Component otherwise it will be redefined on every run cousing a complete remount
// avoid flat checkboxes indentation
const Styles = styled.div`
.card {
  min-height: 100%
}
.max-vh-50 {
  overflow: auto;
  max-height: 50vh;
}
.react-checkbox-tree {
  line-height: 1.3;
  &> ol > .rct-node:not(.rct-node-parent) > .rct-text > span.rct-collapse {
    display: none;
  }
  label {
    font-weight: 400;
  }
}
`

function Selector({
  cohorts,
  collectors,
  handleShowLegend,
  checkedNodes,
  expandedNodes,
  collectorTags,
  liftupHistoriesData = {},
  checkedCohorts,
  handleCollectorCheckboxChange,
  handleCohortCheckboxChange,
  handleCollectorCheckboxExpand,
  disabled,
}) {
  // console.debug('rendering Selector')

  const [disabledNodes, setDisabledNodes] = useState({})
  const [filteredNodes, setFilteredNodes] = useState({})
  const [titleMode, setTitleMode] = useState(false)
  const [isFiltering, setIsFiltering] = useState(false)
  const [filter, setFilter] = useState('')

  const getInvolvedNodes = (cId, checked) => {
    console.debug(`recalculating involved nodes in collector ${cId}`)
    checked ||= (checkedNodes[cId] || [])

    if (!checked.length) return

    let involvedNodes = new Set()

    // add fields involved in liftUp history
    const liftUpHistory = new LiftUpHistory(liftupHistoriesData[cId])
    _.eachDeep(collectors[cId], (e) => {
      if (
        liftUpHistory.involvedDataPaths.length
        && e.data_path.length
        && (e.data_type == 'scalar' || e.data_type == 'array_of_scalars')
        && _.some(liftUpHistory.involvedDataPaths, n => _.startsWith(n, e.data_path))
      )
        involvedNodes.add(e.id)
    }, { childrenPath: 'children' })

    checked = _.union(checked, Array.from(involvedNodes))

    // add all involved ancestors key fields
    _.eachDeep(collectors[cId], (e) => {
      const keyChild = _.find(e.children, 'is_key')
      if (keyChild && checked?.find(j => _.startsWith(j, e.id) && j != keyChild.id)) {
        involvedNodes.add(keyChild.id)
      }
    }, { childrenPath: 'children' })

    return Array.from(involvedNodes)
  }

  const getFilteredNodes = (cId) => {
    console.debug(`recalculating filtered nodes in collector ${cId}`)

    // if (filter.length <= 2) return

    let filteredSet = new Set()
    _.filterDeep(collectors[cId], (value) => {
      if (_.isPlainObject(value)) {
        if (value.type == 'root' || (value.name?.toLowerCase().match(filter.toLowerCase())
          || (value.title && !_.isPlainObject(value.title) && value.title.toLowerCase().match(filter.toLowerCase())))) {
          filteredSet.add(value.id)
        }
        else
          filteredSet.delete(value.id)
      }
    }, { childrenPath: 'children' })

    // set add all involved ancestors visibility
    _.eachDeep(collectors[cId], (value) => {
      if (value.data_type != 'scalar' && [...filteredSet].find(nodeId => _.startsWith(nodeId, value.id))) {
        filteredSet.add(value.id)
      }
    }, { childrenPath: 'children' })

    return [...filteredSet]
  }

  const checkBoxProps = ['id', 'name', 'data_type', 'title', 'description', 'note', 'children', 'is_key']
  let collectorsData = _.mapValues(collectors, (v, cId) => {
    // console.log(_.pick( v, checkBoxProps))
    let obj = _.pickDeep(v, checkBoxProps)
    return _.mapValuesDeep(obj, (value, key, parentValue) => {
      const legendButton = !parentValue
        ? (
          <OverlayTrigger overlay={<Tooltip id="tooltip2">Show Legend</Tooltip>} placement="top">
            <Button className="ml-1" onClick={() => handleShowLegend(cId)} size="xs" variant="link">
              <i className="fa fa-list" />
            </Button>
          </OverlayTrigger>
          )
        : null

      const tagsList = !parentValue
        ? (
          <span className="ml-1">
            {
              collectorTags[cId].length
                ? collectorTags[cId].map(tag => (
                  <span className="badge badge-light" key={tag}>
                    {tag}
                  </span>
                ))
                : null
            }
          </span>
          )
        : null

      if (_.isPlainObject(value)) {
        const label = (
          <span>
            <CheckBoxLabel {..._.pick(value, checkBoxProps)} titleMode={titleMode} />
            {tagsList}
            {legendButton}
          </span>
        )
        return {
          value: value.id,
          children: value.children,
          label: label,
          disabled: disabledNodes[cId]?.includes(value.id),
          className: !!filteredNodes[cId] && !filteredNodes[cId].includes(value.id) ? 'd-none' : '',
        }
      }
      else {
        return value
      }
    })
  })

  useEffect(() => {
    setDisabledNodes(_.mapValues(collectors, (c, cId) => getInvolvedNodes(cId)))
  }, [liftupHistoriesData, checkedNodes])
  useEffect(() => {
    setFilteredNodes(_.mapValues(collectors, (c, cId) => getFilteredNodes(cId)))
  }, [filter])

  const handleChangeFilterText = _.debounce((text) => {
    setFilter(text)
    setIsFiltering(false)
  }, 1000)

  const debouncedHandleChangeFilterText = useCallback(handleChangeFilterText, [])

  let warnMessage
  if (filter) {
    const tooltip
     = (
       <Tooltip>
         Filtering is on. Clear filter to see all fields.
       </Tooltip>
     )

    warnMessage = (
      <OverlayTrigger overlay={tooltip} placement="right">
        <i className="fa fa-exclamation-triangle text-warning" />
      </OverlayTrigger>
    )
  }

  return (
    <Styles>
      <Row className="mb-3">
        <Col md={6}>
          <Card className="card-outline card-secondary card-compact">
            <Card.Header>
              <Card.Title>
                Collectors
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Form inline>
                <InputGroup>
                  <Form.Control
                    onChange={(e) => {
                      debouncedHandleChangeFilterText(e.target.value)
                      setIsFiltering(true)
                    }}
                    placeholder="Type to filter fields..."
                    type="text"
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>
                      <i className="fas fa-filter" />
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
                <Form.Check className="ml-2 mr-2">
                  <Form.Check.Label>
                    <Form.Check.Input
                      checked={titleMode}
                      onChange={e => setTitleMode(e.target.checked)}
                      type="checkbox"
                    />
                    Show titles
                  </Form.Check.Label>
                </Form.Check>
                {warnMessage}
              </Form>
              <LoadingOverlay
                active={isFiltering}
                styles={{
                  overlay: base => ({
                    ...base,
                    background: 'rgba(0, 0, 0, 0.3)',
                  }),
                }}
                text="Loading ..."
                className="max-vh-50 mt-1 masked-overflow"
              >
                {_.map(collectorsData, (cData, cId) => (
                  <div className="mt-1" key={cId}>
                    <CheckboxTree
                      checkModel="all"
                      checked={checkedNodes[cId]}
                      disabled={disabled}
                      expandDisabled={disabled}
                      expanded={expandedNodes[cId]}
                      iconsClass="fa5"
                      key={cId}
                      nativeCheckboxes={true}
                      nodes={[cData]}
                      onCheck={(checked, target) => {
                        if (!target.checked) { // when uncheck a node also force uncheck of its children disabled nodes
                          checked = target.treeDepth ? _.filter(checked, v => !_.startsWith(v, `${target.value}.`)) : []
                        }
                        // add involved nodes to checked nodes
                        handleCollectorCheckboxChange(cId, _.union(checked, getInvolvedNodes(cId, checked)))
                      }}
                      onExpand={expanded => handleCollectorCheckboxExpand(cId, expanded)}
                      showExpandAll={true}
                      showNodeIcon={false}
                    />
                  </div>
                )
                )}
              </LoadingOverlay>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card bsPrefix="card card-outline card-secondary card-compact">
            <Card.Header>
              <Card.Title>
                Cohorts
              </Card.Title>
            </Card.Header>
            <Card.Body
              className="max-vh-50"
            >
              <small>
                <Button className="p-1" disabled={cohorts.length == checkedCohorts.length} onClick={() => handleCohortCheckboxChange(_.map(cohorts, 'value'))} variant="link">
                  all
                </Button>
                /
                <Button className="p-1" disabled={_.isEmpty(checkedCohorts)} onClick={() => handleCohortCheckboxChange([])} variant="link">
                  none
                </Button>
              </small>
              <CheckboxTree
                checked={checkedCohorts}
                disabled={disabled}
                expandDisabled={true}
                iconsClass="fa5"
                nativeCheckboxes={true}
                nodes={cohorts}
                onCheck={checked => handleCohortCheckboxChange(checked)}
                showNodeIcon={false}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Styles>
  )
}

Selector.propTypes = {
  cohorts: PropTypes.array.isRequired,
  collectors: PropTypes.object.isRequired,
  handleShowLegend: PropTypes.func.isRequired,
  checkedNodes: PropTypes.object.isRequired,
  expandedNodes: PropTypes.object.isRequired,
  liftupHistoriesData: PropTypes.object,
  checkedCohorts: PropTypes.array.isRequired,
  handleCollectorCheckboxChange: PropTypes.func.isRequired,
  handleCohortCheckboxChange: PropTypes.func.isRequired,
  handleCollectorCheckboxExpand: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  collectorTags: PropTypes.object,
}

export default memo(Selector)
