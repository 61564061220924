import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Card, Form, Row, Col } from 'react-bootstrap'
import { HelpPopper } from '../../utils.jsx'

function OptionsManager({
  options,
  handleOptionsChange,
  disabled,
  allPidTypes,
  refPidType,
}) {
  // console.debug('rendering OptionsManager')

  const handleOptionsChangeProxy = (e) => {
    let newOptions = {
      ...options,
      [e.target.id]: e.target.checked,
    }
    handleOptionsChange(newOptions)
  }

  const handleIdsChange = (e) => {
    let newOptions = {
      ...options,
      ids: e.target.checked ? [...options.ids, e.target.id] : _.without(options.ids, e.target.id),
    }
    handleOptionsChange(newOptions)
  }

  return (
    <Card bsPrefix="card card-outline card-secondary card-compact">
      <Card.Header>
        <Card.Title>
          Options
        </Card.Title>
        <div className="card-tools">
          <button className="btn btn-tool" data-card-widget="collapse" type="button"><i className="fas fa-plus" /></button>
        </div>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col sm={6}>
            <h6 className="font-weight-bold">Main sheet</h6>
            <Form>
              <Form.Check.Label
                className="mr-2"
              >
                Show
              </Form.Check.Label>
              {
                allPidTypes.map(id =>
                  (
                    <Form.Check
                      checked={_.includes(options.ids, id)}
                      disabled={disabled || id == refPidType}
                      id={id}
                      inline
                      key={id}
                      label={id}
                      onChange={handleIdsChange}
                      type="checkbox"
                    />
                  )
                )
              }
              <Form.Check
                checked={options.showTimestamps}
                disabled={disabled}
                id="showTimestamps"
                label="Show timestamps"
                onChange={handleOptionsChangeProxy}
                type="checkbox"
              />
              <Form.Check
                id="useSelection"
              >
                <Form.Check.Input
                  checked={options.useSelection}
                  disabled={disabled}
                  onChange={handleOptionsChangeProxy}
                  type="checkbox"
                />
                <Form.Check.Label>Enable participant selection</Form.Check.Label>
                <HelpPopper>
                  By enabling selection only selected participants will be exported. Leave disabled if you want to export all participants
                </HelpPopper>
              </Form.Check>

            </Form>
          </Col>
          <Col sm={6}>
            <h6 className="font-weight-bold">Secondary sheets</h6>
            <Form>
              <Form.Check
                checked={options.showPersonIdsInAllSheets}
                disabled={disabled}
                id="showPersonIdsInAllSheets"
                label="Include all IDs"
                onChange={handleOptionsChangeProxy}
                type="checkbox"
              />
              <Form.Check
                checked={options.showAllAncestorsKeys}
                disabled={disabled}
                id="showAllAncestorsKeys"
                label="Show all ancestor keys"
                onChange={handleOptionsChangeProxy}
                type="checkbox"
              />
              <Form.Check
                checked={options.showSequentialIds}
                disabled={disabled}
                id="showSequentialIds"
                label="Include sequential ids"
                onChange={handleOptionsChangeProxy}
                type="checkbox"
              />
            </Form>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

OptionsManager.propTypes = {
  options: PropTypes.object.isRequired,
  handleOptionsChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  allPidTypes: PropTypes.array.isRequired,
  refPidType: PropTypes.string.isRequired,
}

export default memo(OptionsManager)
