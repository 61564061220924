import React, { useEffect, useState, useCallback, memo } from 'react'
import PropTypes from 'prop-types'
import { Button, Tooltip, OverlayTrigger } from 'react-bootstrap'
import { xFetch } from '../../utils'
import DatabaseWidgetModal from './database_widget_modal.jsx'

function DatabaseWidget({
  projectId,
  currentView,
  collectorId,
  workbook,
  exportParameters,
  canManage = false,
}) {
  const [exportDbData, setExportDbData] = useState()
  const [showDatabaseModal, setShowDatabaseModal] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const findDb = useCallback(
    () => {
      if (!!projectId && !!currentView.id && !!collectorId) {
        setLoading(true)
        xFetch(Routes.project_data_visualizer_export_db_path(projectId, currentView.id, collectorId))
          .then((data) => {
            setExportDbData(_.pick(data, 'database_params', 'is_ready', 'success', 'last_outcome_message', 'updated_at'))
          })
          .catch((data) => {
            setExportDbData()
            console.warn(data.msgs.join('\n'))
          })
          .finally(() => setLoading(false))
      }
    },
    [
      projectId,
      currentView.id,
      collectorId,
    ]
  )

  const prepareWorkbook = () => {
    const refPidType = workbook.main.idName

    return _.map(_.values(workbook), sheet => ({
      name: sheet.tableName,
      fields: _.map(_.filter(sheet.fields, f => (!f.hidden || f.type == 'spKey' || f.type == 'sfKey') && f.type != 'fKey'), f => ({ name: f.name.replaceAll('.', '_'), contentType: f.contentType || 'string', type: f.type, maxVal: f.maxVal, maxLength: f.maxLength })),
      primaryKey: sheet.idName,
      values: exportParameters.options.useSelection
        ? sheet.values.filter(v => _.includes(exportParameters.selected_pids, v[refPidType]))
        : sheet.values,
      parent: sheet.parent?.tableName,
      parentPrimaryKey: sheet.parent?.idName,
      foreignKey: sheet.parent?.foreignIdName,
    })
    )
  }

  useEffect(() => {
    findDb()
  }, [findDb])

  let interval
  useEffect(() => {
    if (exportDbData && !exportDbData.is_ready) {
      interval = setInterval(() => {
        findDb()
      }, 5000)
    }
    else {
      clearInterval(interval)
    }
    return () => clearInterval(interval)
  }, [exportDbData?.is_ready])

  function createDb() {
    setLoading(true)
    xFetch(Routes.project_data_visualizer_export_db_path(projectId, currentView.id, collectorId), {
      method: 'POST',
      body: JSON.stringify({
        id: currentView.id,
        collector_id: collectorId,
        data_model: JSON.stringify(prepareWorkbook()),
        export_params: JSON.stringify(exportParameters),
      }),
    })
      .then((data) => {
        toastr.info('The request was submitted')
        setExportDbData(_.pick(data, 'database_params', 'is_ready', 'success', 'last_outcome_message', 'updated_at'))
      })
      .catch((error) => {
        console.error(error.msgs.join('\n'))
        toastr.error('Unable to submit the request:<br/>' + error.msgs.join('<br/>'))
      })
      .finally(() => setLoading(false))
  }

  function syncDbNow() {
    setLoading(true)
    xFetch(Routes.project_data_visualizer_export_db_path(projectId, currentView.id, collectorId), {
      method: 'PUT',
      body: JSON.stringify({
        data_model: JSON.stringify(prepareWorkbook()),
        export_params: JSON.stringify(exportParameters),
      }),
    })
      .then((data) => {
        toastr.info('The request was submitted')
        setExportDbData(_.pick(data, 'database_params', 'is_ready', 'success', 'last_outcome_message', 'updated_at'))
      })
      .catch((error) => {
        console.error(error.msgs.join('\n'))
        toastr.error('Unable to submit the request:<br/>' + error.msgs.join('<br/>'))
      })
      .finally(() => setLoading(false))
  }

  function destroyVisualizerDb() {
    setLoading(true)
    return xFetch(Routes.project_data_visualizer_export_db_path(projectId, currentView.id, collectorId), {
      method: 'DELETE',
    })
      .then(() => {
        toastr.info('The request was submitted')
        setExportDbData()
      })
      .catch((error) => {
        console.error(error.msgs.join('\n'))
        toastr.error('Unable to submit the request:<br/>' + error.msgs.join('<br/>'))
      })
      .finally(() => {
        setLoading(false)
        setShowDatabaseModal(false)
      })
  }

  let tooltipMsg, alertTriangle
  if (exportDbData) {
    if (moment(exportDbData.updated_at) < moment(currentView.updated_at)) {
      tooltipMsg = (
        <span>
          Database was not updated after current view last update.
          <strong>They may be not synced</strong>
        </span>
      )
    }

    if (!exportDbData.is_ready) {
      tooltipMsg = <span>Database is under construction. Please wait</span>
    }
    else {
      if (!exportDbData.success) {
        tooltipMsg = <span>There was a problem while building the database. Open for more info</span>
      }
    }

    alertTriangle = tooltipMsg && (
      <OverlayTrigger overlay={<Tooltip>{tooltipMsg}</Tooltip>} placement="right">
        <i className={`fa fa-exclamation-triangle ${exportDbData.is_ready && !exportDbData.success ? 'text-danger' : 'text-warning'}`} />
      </OverlayTrigger>
    )
  }

  return (
    <div>
      {
        exportDbData
          ? (
              <>
                <Button
                  className="mr-2"
                  disabled={isLoading}
                  onClick={() => setShowDatabaseModal(true)}
                  size="sm"
                  title="Show database"
                  variant="outline-primary"
                >
                  <span><i className="fa fa-database" /></span>
                  {' '}
                  Exported database
                  <span className="ml-1">
                    {alertTriangle}
                  </span>
                </Button>
                <DatabaseWidgetModal
                  canManage={canManage}
                  exportDbData={exportDbData}
                  handleClose={setShowDatabaseModal}
                  handleDestroyDb={destroyVisualizerDb}
                  handleRefresh={findDb}
                  handleSyncDbNow={syncDbNow}
                  isLoading={isLoading}
                  show={showDatabaseModal}
                />
              </>
            )
          : (
              <Button
                disabled={isLoading}
                onClick={createDb}
                size="sm"
                title="Export as database"
                variant="secondary"
              >
                <span><i className="fa fa-database" /></span>
                {' '}
                Export workbook as database
              </Button>
            )
      }
      {
        isLoading
          ? (
              <span className="ml-2">
                <i className="fas fa-spinner fa-spin" />
                {' '}
              </span>
            )
          : null
      }
    </div>
  )
}

DatabaseWidget.propTypes = {
  workbook: PropTypes.object.isRequired,
  projectId: PropTypes.number.isRequired,
  currentView: PropTypes.object.isRequired,
  collectorId: PropTypes.string.isRequired,
  exportParameters: PropTypes.object.isRequired,
  canManage: PropTypes.bool,
}

export default memo(DatabaseWidget)
