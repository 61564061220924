import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import Papa from 'papaparse'

async function packFiles(files, archiveName, password) {
  // Create a FormData object
  const formData = new FormData()

  files = [files].flat().forEach((file) => {
    formData.append('files', file)
  })

  if (password) {
    formData.append('password', password)
  }

  try {
    // Send the POST request
    const response = await fetch('/fns/pack', {
      method: 'POST',
      body: formData,
    })

    // Handle the response
    if (response.ok) {
      const blob = await response.blob()

      // Create a link to download the compressed archive
      const downloadUrl = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = downloadUrl
      a.download = `${archiveName}.zip` // Default archive name
      document.body.appendChild(a)
      a.click()
      a.remove()

      // Revoke the URL after download
      URL.revokeObjectURL(downloadUrl)
    }
    else {
      console.error('Failed to upload files:', response.statusText)
    }
  }
  catch (error) {
    console.error('Error:', error)
  }
}

const exportExcel = ({ data, filename, selectedPids, refPidType, password }) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  let wb = { Sheets: {}, SheetNames: [] }

  const addSheet = (sheet) => {
    const
      filteredValues = selectedPids ? sheet.values.filter(v => _.includes(selectedPids, v[refPidType])) : sheet.values,
      fieldNames = _.map(_.filter(sheet.fields, f => !f.hidden), f => f.name.replaceAll('.', '_'))

    wb.Sheets[sheet.excelName] = XLSX.utils.aoa_to_sheet([fieldNames].concat(filteredValues ? filteredValues.map(row => fieldNames.map(k => row[k])) : []))
    wb.SheetNames.push(sheet.excelName)
  }

  if (_.isPlainObject(data)) {
    addSheet(data)
    filename = data.excelName
  }
  else {
    _.forEach(data, sheet => addSheet(sheet))
  }

  filename += '.xlsx'

  const
    excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' }),
    blob = new Blob([excelBuffer], { type: fileType })
  if (password) {
    const file = new File([blob], filename, { type: blob.type })
    packFiles(file, filename, password)
  }
  else {
    FileSaver.saveAs(blob, filename)
  }
}

const exportCSV = ({ data, filename, selectedPids, refPidType, password }) => {
  const fileType = 'text/csv;charset=UTF-8'

  const prepareFile = (data, name) => {
    const
      filteredValues = selectedPids ? data.values.filter(v => _.includes(selectedPids, v[refPidType])) : data.values,
      fieldNames = _.map(_.filter(data.fields, f => !f.hidden), f => f.name.replaceAll('.', '_')),
      csv = Papa.unparse(filteredValues, { columns: fieldNames })
    if (csv == null) return

    const blob = new Blob([csv], { type: fileType })
    return new File([blob], name, { type: blob.type })
  }

  let files = []

  if (_.isPlainObject(data)) {
    if (password) {
      files.push(prepareFile(data, data.name + '.csv'))
      packFiles(files, data.name + '.csv', password)
    }
    else {
      FileSaver.saveAs(prepareFile(data), data.name + '.csv')
      return
    }
  }
  else {
    _.forEach(data, (sheet) => {
      files.push(prepareFile(sheet, sheet.name + '.csv'))
    })
    packFiles(files, filename, password)
  }
}

const exportHtml = ({ bodyContent, style, filename, title }) => {
  const
    fileType = 'text/html',
    fileContent = `<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-UA-Compatible" content="ie=edge">
    <style type="text/css" media="screen">
      ${style}
    </style>
    <title>${title}</title>
  </head>
  <body>
    ${bodyContent}
  </body>
</html>`,
    outData = new Blob([fileContent], { type: fileType })

  FileSaver.saveAs(outData, (filename || _.snakeCase(title)) + '.html')
}

function getHeaderRow(sheet) {
  var headers = []
  var range = XLSX.utils.decode_range(sheet['!ref'])
  var C, R = range.s.r /* start in the first row */
  /* walk every column in the range */
  for (C = range.s.c; C <= range.e.c; ++C) {
    var cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })] /* find the cell in the first row */

    var hdr = 'UNKNOWN ' + C // <-- replace with your desired default
    if (cell && cell.t) hdr = XLSX.utils.format_cell(cell)

    headers.push(hdr)
  }
  return headers
}

export { exportExcel, exportCSV, exportHtml }
