import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, Container, Col, Row, Form } from 'react-bootstrap'
import styled from 'styled-components'
import { bootstrapSelectStyle, HelpPopper } from '../../utils'
import Select from 'react-select'

// need to be placed outside the function Component otherwise it will be redefined on every run cousing a complete remount
const Styles = styled.div`
  .form {
    align-items: start;
      .form-group, .form-label {
        margin-right: 1rem;
      }
  }
`

const nameinvalidFeedback = (
  <Form.Control.Feedback type="invalid">
    <ul className="pl-3">
      <li>Required</li>
      <li>must not be present in parent sheet</li>
      <li>can contain only letters, digits and underscores</li>
      <li>must not start with an underscore</li>
    </ul>
  </Form.Control.Feedback>
)

const aggFormulasByType = {
  string: ['count', 'concat'],
  float: ['count', 'concat', 'min', 'max', 'sum', 'avg'],
  integer: ['count', 'concat', 'min', 'max', 'sum', 'avg'],
  boolean: [],
}

function AggFields({
  sheetId,
  arePresentInParent,
  handleChange,
  selectedSheetIsMadeOfScalars,
  fieldOpts,
  fieldsData,
  fieldNameRegexp,
}) {
  const [data, setData] = useState({
    field: selectedSheetIsMadeOfScalars ? _.first(fieldOpts).value : undefined,
    name: selectedSheetIsMadeOfScalars ? _.last(sheetId.split('.')) : undefined,
    formula: undefined,
  })
  const [invalidFields, setInvalidFields] = useState({})
  const [validated, setValidated] = useState(false)

  useEffect(() => {
    const invalidFields = {
      field: !data.field,
      name: !data.name || arePresentInParent([data.name]) || !data.name.match(fieldNameRegexp),
      formula: !data.formula,
    }
    setInvalidFields(invalidFields)
    setValidated(true)
    handleChange('agg', data, _.some(invalidFields))
  }, [data])

  const formulaOpts = _.map(data.field && fieldsData[data.field] ? aggFormulasByType[fieldsData[data.field].contentType] : [], k => ({ value: k, label: k }))

  return (
    <>
      <Col md={3}>
        <Form.Group controlId="form.field">
          <Form.Label>
            Field
            <HelpPopper>
              The field you want to view in the parent sheet
            </HelpPopper>
          </Form.Label>
          <Select
            className={invalidFields['field'] ? 'is-invalid' : null}
            components={{ IndicatorSeparator: () => null }}
            isInvalid={invalidFields['field']}
            onChange={opt => setData({ ...data, field: opt.value, name: [_.last(sheetId.split('.')), selectedSheetIsMadeOfScalars ? null : opt.value].join('_'), formula: undefined })}
            options={fieldOpts}
            placeholder="Please select ...."
            styles={bootstrapSelectStyle}
            validated={validated}
            value={_.find(fieldOpts, opt => data.field == opt.value) || null}
          />
          <Form.Control.Feedback type="invalid">
            Required
          </Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col md={2}>
        <Form.Group controlId="form.formula">
          <Form.Label>
            Formula
            <HelpPopper>
              The aggregation formula that will be used. Available options depends on the type of the field. For example math formulas are available for numeric fields
            </HelpPopper>
          </Form.Label>
          <Select
            className={invalidFields['formula'] ? 'is-invalid' : null}
            components={{ IndicatorSeparator: () => null }}
            isInvalid={invalidFields['formula']}
            onChange={opt => setData({ ...data, formula: opt.value })}
            options={formulaOpts}
            placeholder="Please select ...."
            styles={bootstrapSelectStyle}
            validated={validated}
            value={_.find(formulaOpts, opt => opt.value == data.formula) || null}
          />
          <Form.Control.Feedback type="invalid">
            Required
          </Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col md={3}>
        <Form.Group controlId="form.name">
          <Form.Label>
            New field name
            <HelpPopper>
              The name that will be used for the lifted up field in the parent sheet
            </HelpPopper>
          </Form.Label>
          <Form.Control isInvalid={invalidFields['name']} onChange={e => setData({ ...data, name: e.target.value })} placeholder="new field name" type="text" value={data.name || ''} />
          {nameinvalidFeedback}
        </Form.Group>
      </Col>
    </>
  )
}

AggFields.propTypes = {
  sheetId: PropTypes.string,
  fieldsData: PropTypes.object,
  fieldOpts: PropTypes.array.isRequired,
  arePresentInParent: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  selectedSheetIsMadeOfScalars: PropTypes.bool,
  fieldNameRegexp: PropTypes.instanceOf(RegExp).isRequired,
}

function ExpFields({
  sheetId,
  handleChange,
  selectedSheetIsMadeOfScalars,
  fieldOpts,
  fieldNameRegexp,
}) {
  const [data, setData] = useState({
    fields: selectedSheetIsMadeOfScalars ? [_.first(fieldOpts).value] : [],
    prefix: _.last(sheetId.split('.')).concat('_'),
  })
  const [invalidFields, setInvalidFields] = useState({})
  const [validated, setValidated] = useState(false)

  useEffect(() => {
    const invalids = {
      fields: !data.fields.length,
      prefix: !data.prefix || !data.prefix.match(fieldNameRegexp),
    }
    setInvalidFields(invalids)
    setValidated(true)
    handleChange('exp', data, _.some(invalids))
  }, [data])

  return (
    <>
      <Col md={5}>
        <Form.Group controlId="form.fields">
          <Form.Label>
            Fields
            <HelpPopper>
              The fields you want to view in the parent sheet
            </HelpPopper>
          </Form.Label>
          <Select
            className={invalidFields['fields'] ? 'is-invalid' : null}
            components={{ IndicatorSeparator: () => null }}
            isInvalid={invalidFields['fields']}
            isMulti={true}
            onChange={opts => setData({ ...data, fields: _.map(opts, 'value') })}
            options={fieldOpts}
            placeholder="Please select ...."
            styles={bootstrapSelectStyle}
            validated={validated}
            value={_.filter(fieldOpts, opt => data.fields.includes(opt.value))}
          />
          <Form.Control.Feedback type="invalid">
            Required
          </Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col md={3}>
        <Form.Group controlId="form.prefix">
          <Form.Label>
            Prefix
            <HelpPopper>The prefix that will be applied to the fields once they are in the parent sheet</HelpPopper>
          </Form.Label>
          <Form.Control isInvalid={invalidFields['prefix']} onChange={e => setData({ ...data, prefix: e.target.value })} placeholder="prefix for new columns" type="text" value={data?.prefix || ''} />
          {nameinvalidFeedback}
        </Form.Group>
      </Col>
    </>
  )
}

ExpFields.propTypes = {
  sheetId: PropTypes.string,
  fieldOpts: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  fieldNameRegexp: PropTypes.instanceOf(RegExp).isRequired,
}

export default function LiftUpStepForm({
  workbook,
  handleSubmit,
  handleCancel,
  fieldNameRegexp,
}) {
  const [data, setData] = useState({})
  const [invalidFields, setInvalidFields] = useState({})
  const [someInvalidNestedFields, setSomeInvalidNestedFields] = useState(true)
  const [validated, setValidated] = useState(false)

  const parentFields = data.sheetId && _.map(workbook[data.sheetId].parent.fields, 'name')

  const arePresentInParent = fields => !!_.intersection(parentFields, fields).length

  const handleChange = (type, aggData, someInvalidNestedFields) => {
    setData({ ...data, type: type, ...aggData })
    setSomeInvalidNestedFields(someInvalidNestedFields)
  }

  useEffect(() => {
    if (!_.isEqual(data, {})) {
      setInvalidFields({
        sheetId: !data.sheetId,
        type: !data.type,
      })
      setValidated(true)
    }
  }, [data])

  const fieldsData = _.mapValues(workbook, (sheet) => {
    return _.keyBy(_.filter(sheet.fields, f => f.type == 'orig' || f.type == 'liftedUp'), 'name') // only original data fields and liftedUp fields can be lifted up
  }, {})
  delete fieldsData.main

  const sheetIdOpts = _.map(_.keys(fieldsData), k => ({ value: k, label: k }))
  const fieldOpts = _.map(fieldsData[data.sheetId], f => ({ value: f.name, label: f.name }))
  const typeOpts = [{ value: 'agg', label: 'aggregated (AGG)' }, { value: 'exp', label: 'expanded (EXP)' }]

  const fieldsProps = {
    key: data.sheetId, // so that sub component is completely remount (reset its state) when sheetId change
    sheetId: data.sheetId,
    arePresentInParent: arePresentInParent,
    handleChange: handleChange,
    selectedSheetIsMadeOfScalars: workbook[data.sheetId]?.isMadeOfScalars,
    fieldOpts: fieldOpts,
    fieldsData: fieldsData[data.sheetId],
    fieldNameRegexp: fieldNameRegexp,
  }
  const typeSpecificFields = {
    agg: <AggFields {...fieldsProps} />,
    exp: <ExpFields {...fieldsProps} />,
  }

  return (
    <Styles>
      <Container fluid>
        <h5>New step</h5>
        <Form noValidate>
          <Row>
            <Col md={2}>
              <Form.Group controlId="form.sheetId">
                <Form.Label>
                  Sheet
                  <HelpPopper>
                    The sheet containing the fields you want to
                    {' '}
                    <i>lift up</i>
                  </HelpPopper>
                </Form.Label>
                <Select
                  className={invalidFields['sheetId'] ? 'is-invalid' : null}
                  components={{ IndicatorSeparator: () => null }}
                  isInvalid={invalidFields['sheetId']}
                  onChange={opt => setData({afterField: 'at the beginning', type: data.type, sheetId: opt.value })}
                  options={sheetIdOpts}
                  placeholder="Please select ...."
                  styles={bootstrapSelectStyle}
                  validated={validated}
                  value={_.find(sheetIdOpts, opt => opt.value == data.sheetId) || null}
                />
                <Form.Control.Feedback type="invalid">
                  Required
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={2}>
              <Form.Group controlId="form.type">
                <Form.Label>
                  Type
                  <HelpPopper>
                    The lift up step type.
                    {' '}
                    <br />
                    <b>Aggregation</b>
                    {' '}
                    will generate a new field in the parent sheet whose value is calculated by an aggregation formula applied to all occourences.
                    <br />
                    <b>Expanded</b>
                    {' '}
                    will generate a new field in the parent sheet by disposing each occourence in a different new field
                  </HelpPopper>
                </Form.Label>
                <Select
                  className={invalidFields['type'] ? 'is-invalid' : null}
                  components={{ IndicatorSeparator: () => null }}
                  isInvalid={invalidFields['type']}
                  onChange={opt => setData({afterField: 'at the beginning', sheetId: data.sheetId, type: opt.value })}
                  options={typeOpts}
                  placeholder="Please select ...."
                  styles={bootstrapSelectStyle}
                  validated={validated}
                  value={_.find(typeOpts, opt => opt.value == data.type) || null}
                />
                <Form.Control.Feedback type="invalid">
                  Required
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            {
              data.type && data.sheetId
                ? typeSpecificFields[data.type]
                : null
            }
          </Row>
          <Row>
            <Col>
              <div className="float-right">
                <Button disabled={_.some(invalidFields) || someInvalidNestedFields} onClick={() => handleSubmit(data)} size="sm" variant="primary">
                  add step
                </Button>
                <Button onClick={() => handleCancel()} size="sm" variant="link">
                  cancel
                </Button>

              </div>
            </Col>
          </Row>
        </Form>
      </Container>
    </Styles>
  )
}
