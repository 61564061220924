import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import ResourcesLister from './resources_lister/resources_lister'
import { xFetch } from './utils'

export default function UsersLister({
  attributes = [],
  canImpersonate = false,
  itemsPath,
  canDownload,
}) {
  const impersonate = function (e, user) {
    e.stopPropagation()
    xFetch(Routes.impersonate_settings_user_path(user.id), {
      method: 'POST',
    })
      .then(() => {
        window.location = Routes.root_path
      })
  }

  const actionCell = (user) => {
    if (canImpersonate) {
      return <Button block disabled={!user.can_be_impersonated} onClick={e => impersonate(e, user)} size="xs" variant="warning">impersonate</Button>
    }
    else {
      return ''
    }
  }

  return (
    <ResourcesLister
      actionCell={actionCell}
      attributes={attributes}
      downloadEnabled={canDownload}
      itemsPath={itemsPath}
    />
  )
}

UsersLister.propTypes = {
  attributes: PropTypes.object,
  canImpersonate: PropTypes.bool,
  itemsPath: PropTypes.string.isRequired,
}
