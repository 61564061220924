import React, { useState, memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import DownloadWidgetModal from './download_widget_modal.jsx'
import { exportExcel, exportCSV } from '../export_functions'

function DownloadWidget({
  workbook,
  collector,
  forceEncryption = false,
  refPidType,
  selectedSheetId,
  selectedPids,
}) {
  const [showDownloadModal, setShowDownloadModal] = useState(false)

  const handleDownload = useCallback(
    ({
      format,
      downloadAll,
      password,
    }) => {
      let exportFunc
      switch (format) {
        case 'excel':
          exportFunc = exportExcel
          break
        case 'csv':
          exportFunc = exportCSV
          break
      }

      exportFunc({
        data: downloadAll ? _.values(workbook) : workbook[selectedSheetId],
        filename: collector.name,
        selectedPids: selectedPids,
        refPidType: refPidType,
        password: password,
      })
      setShowDownloadModal(false)
    },
    [
      collector.name,
      refPidType,
      selectedPids,
      selectedSheetId,
      workbook,
    ],
  )

  return (
    <>
      <Button
        className="float-right"
        disabled={_.isArray(selectedPids) ? _.isEmpty(selectedPids) : null}
        onClick={() => setShowDownloadModal(true)}
        size="sm"
        title="download"
        variant="secondary"
      >
        <i className="fas fa-download" />
        {' '}
        Download
      </Button>
      {
        showDownloadModal
          ? (
              <DownloadWidgetModal
                currentSheetName={workbook[selectedSheetId].id}
                forceEncryption={forceEncryption}
                handleClose={setShowDownloadModal}
                handleDownload={handleDownload}
                selectedSheetId={selectedSheetId}
                show={showDownloadModal}
              />
            )
          : null

      }

    </>
  )
}

DownloadWidget.propTypes = {
  workbook: PropTypes.object.isRequired,
  collector: PropTypes.object.isRequired,
  forceEncryption: PropTypes.bool,
  refPidType: PropTypes.string.isRequired,
  selectedSheetId: PropTypes.string.isRequired,
  selectedPids: PropTypes.array,
}

export default memo(DownloadWidget)
