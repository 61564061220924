import React, { useEffect, useState } from 'react'
import { Button, Modal, Alert } from 'react-bootstrap'
import { keepSessionAlive, keepSessionAliveThrottleTimeInSecs } from './utils'

import styled from 'styled-components'

const Styles = styled.div`
  .alert {
    margin-bottom: 0px;
    text-align: center;
    a {
      text-decoration: unset;
    }
  }
`

export default function SessionWarnWidget() {
  const [remainingTime, setRemainingTime] = useState()

  const sessionExpiresAt = moment.unix(parseInt(getCookie('session-expires-at')))

  const refreshSession = () => {
    keepSessionAlive()
  }

  useEffect(() => {
    const interval = setTimeout(() => {
      setRemainingTime(sessionExpiresAt.diff(moment(), 'seconds'))
      if (remainingTime < keepSessionAliveThrottleTimeInSecs) {
        $('body .wrapper')[0].classList.add('blurred')
        $('body footer')[0].classList.add('blurred')
        $('body > .modal:not(.session-widget-modal)')[0]?.classList?.add('blurred')
      }
      else {
        $('body .wrapper')[0].classList.remove('blurred')
        $('body footer')[0].classList.remove('blurred')
        $('body > .modal:not(.session-widget-modal)')[0]?.classList?.remove('blurred')
      }
    }, 1000)
    return () => clearTimeout(interval)
  }, [remainingTime, sessionExpiresAt])

  return (
    <Modal
      className="session-widget-modal"
      show={remainingTime < keepSessionAliveThrottleTimeInSecs}
      style={{ marginTop: '200px' }}
    >
      <Styles>
        <Alert variant="warning">
          <Alert.Heading>
            <i className="fa fa-exclamation-triangle mr-2" />
            {
              remainingTime <= 0
                ? 'Session expired'
                : `Session will expire in ${remainingTime} seconds`
            }
          </Alert.Heading>
          {
            remainingTime <= 0
              ? (
                  <span>
                    You need to
                    <a className="" onClick={() => window.location.reload()}> sign in </a>
                    again to continue.
                  </span>
                )
              : (
                  <Button block onClick={refreshSession} variant="primary">
                    I am still here!
                  </Button>
                )
          }
        </Alert>
      </Styles>
    </Modal>
  )
}
