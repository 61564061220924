import React, { useState, useEffect, useMemo } from 'react'

// A debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}) {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return (
    <input {...props} onChange={e => setValue(e.target.value)} value={value} />
  )
}

// This is a custom UI for our "between" or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRange({
  column,
  table,
}) {
  const columnFilterValue = column.getFilterValue()
  const min = Number(column.getFacetedMinMaxValues()?.[0] ?? '')
  const max = Number(column.getFacetedMinMaxValues()?.[1] ?? '')

  const handleChangeValue = (value) => {
    let
      from = parseFloat(value?.[0]),
      to = parseFloat(value?.[1])

    if (columnFilterValue || !_.isNaN(from) || !_.isNaN(to)) {
      if (from > to) { // nosense!
        column.setFilterValue(old => [value[1], value[0]]) // reverse because react-table NumberRange filterFn do the same as a rescue strategy
      }
      else {
        column.setFilterValue(old => [value[0] || '', value[1] || ''])
      }
    }
  }

  useEffect(() => {
    return () => column.setFilterValue()
  }, [])

  return (
    <>
      <DebouncedInput
        className="flex-fill mr-1"
        min={min}
        onChange={v =>
          handleChangeValue([v, columnFilterValue?.[1]])}
        placeholder={_.isNil(min) ? null : `≥ ${min}`}
        style={{ width: '5rem' }}
        type="number"
        value={columnFilterValue?.[0] ?? ''}
      />
      to
      <DebouncedInput
        className="flex-fill ml-1"
        max={max}
        onChange={v =>
          handleChangeValue([columnFilterValue?.[0], v])}
        placeholder={_.isNil(min) ? null : `≤ ${max}`}
        type="number"
        value={columnFilterValue?.[1] ?? ''}
      />
    </>
  )
}

function StringSearch({
  column,
  table,
}) {
  const columnFilterValue = column.getFilterValue()

  const sortedUniqueValues = useMemo(
    () => {
      return Array.from(column.getFacetedUniqueValues().keys()).sort()
    },
    [column.getFacetedUniqueValues()]
  )

  useEffect(() => {
    return () => column.setFilterValue()
  }, [])

  return (
    <>
      <datalist id={column.id + 'list'}>
        {sortedUniqueValues.slice(0, 5000).map((value, i) => (
          <option key={i} value={value} />
        ))}
      </datalist>
      <DebouncedInput
        className="flex-fill"
        list={column.id + 'list'}
        onChange={value => column.setFilterValue(value)}
        placeholder={`Search... (${sortedUniqueValues.length})`}
        type="text"
        value={columnFilterValue ?? ''}
      />
    </>
  )
}

function BooleanSelect({
  column,
  table,
}) {
  const columnFilterValue = column.getFilterValue()
  let uniqueValues = column.getFacetedUniqueValues()

  uniqueValues.delete(undefined)

  useEffect(() => {
    return () => column.setFilterValue()
  }, [])

  return (
    <select
      className="flex-fill"
      onChange={(e) => {
        column.setFilterValue(e.target.value ? e.target.value.toLowerCase() === 'true' : undefined)
      }}
      value={columnFilterValue ?? ''}
    >
      <option value="">All</option>
      {Array.from(uniqueValues.keys()).map((option, i) => (
        <option key={i} value={option?.toString()}>
          {`${option?.toString()} [${uniqueValues.get(option)}]`}
        </option>
      ))}
    </select>
  )
}

export { StringSearch, BooleanSelect, NumberRange }
