import React, { useMemo, useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Selector from './selector'
import Visualizer from './visualizer'
import CollectorLegendModal from '../../collector_legend_modal'
import { xFetch, WithSpinner } from '../../utils'
import { diff } from 'jsondiffpatch/with-text-diffs'
import * as consoleFormatter from 'jsondiffpatch/formatters/console'
import ViewsSelector from './views_selector'
import OptionsManager from './options_manager'
import styled from 'styled-components'

export default function ProjectDataVisualizer({
  cohorts,
  collectors,
  privileges,
  projectId,
  viewId,
  refPidType,
  allPidTypes,
  fieldNameRegexpString,
}) {
  // console.debug('rendering ProjectDataVisualizer')

  const [isLoading, setIsLoading] = useState(true)
  const [showLegendId, setShowLegendId] = useState()
  const [checkedNodes, setCheckedNodes] = useState({})
  const [expandedNodes, setExpandedNodes] = useState({})
  const [checkedCohorts, setCheckedCohorts] = useState([])
  const [views, setViews] = useState([])
  const [currentViewId, setCurrentViewId] = useState(undefined)
  const [selectedPids, setSelectedPids] = useState()
  const [liftUpHistories, setLiftUpHistories] = useState({})
  const [options, setOptions] = useState({
    ids: [refPidType],
    useSelection: false,
    showTimestamps: false,
    showPersonIdsInAllSheets: true,
    showAllAncestorsKeys: false,
    showSequentialIds: false,
  })

  const fieldNameRegexp = useMemo(() => new RegExp(fieldNameRegexpString), [fieldNameRegexpString])
  const collectorDataModels = useMemo(() => _.mapValues(collectors, 'data_model'), [collectors])
  const collectorTags = useMemo(() => _.mapValues(collectors, 'tags'), [collectors])

  useEffect(() => {
    xFetch(Routes.project_data_visualizer_views_path(projectId))
      .then((data) => {
        setViews(data)
        setIsLoading(false)
      })
  }, [
    projectId,
  ])

  useEffect(() => {
    if (viewId) {
      handleChangeView(viewId)
    }
  }, [
    handleChangeView,
    viewId,
    views,
  ])

  useEffect(() => {
    if (currentViewId) {
      handleChangeView(currentViewId)
    }
  }, [
    handleChangeView,
    currentViewId,
  ])

  useEffect(() => {
    updateAddressBar()
  }, [
    currentViewId,
    updateAddressBar,
  ])

  const setDefaultState = useCallback(
    () => {
      setCurrentViewId()
      setCheckedNodes({})
      setExpandedNodes({})
      setCheckedCohorts([])
      setSelectedPids()
      setLiftUpHistories({})
      setOptions({ ids: [refPidType], showPersonIdsInAllSheets: true, showAllAncestorsKeys: false, showSequentialIds: false })
    },
    [
      refPidType,
    ]
  )

  const forceEncryptionOnExport = useMemo(() => options.ids.includes('osrID'), [options.ids])

  const viewDataFromState = useMemo(() => (
    {
      checkedNodes,
      expandedNodes,
      checkedCohorts,
      selectedPids,
      liftUpHistories,
      options,
    }
  ), [
    checkedNodes,
    expandedNodes,
    checkedCohorts,
    selectedPids,
    liftUpHistories,
    options,
  ])

  const updateAddressBar = useCallback(
    () => {
      let newUrl = new URL(window.location)
      if (currentViewId) {
        newUrl.searchParams.set('view_id', currentViewId)
      }
      else {
        newUrl.searchParams.delete('view_id')
      }
      history.pushState({}, null, newUrl)
    },
    [
      currentViewId,
    ]
  )

  const handleCollectorCheckboxExpand = useCallback((cId, expanded) => {
    let newExpandedNodes = { ...expandedNodes, [cId]: expanded }
    !expanded.includes(cId) && delete (newExpandedNodes[cId])
    setExpandedNodes(newExpandedNodes)
  }, [expandedNodes])

  const handleCollectorCheckboxChange = useCallback((cId, checked) => {
    let newCheckedNodes = { ...checkedNodes, [cId]: checked }
    _.isEmpty(newCheckedNodes[cId]) && delete (newCheckedNodes[cId])
    setCheckedNodes(newCheckedNodes)
  }, [checkedNodes])

  const handleCohortCheckboxChange = useCallback((checked) => {
    checked = _.map(checked, cId => cId.toString()) // make sure cId is saved as string
    if (_.keys(checkedCohorts) > _.keys(checked) && selectedPids != undefined) { // empty selectedPids (if selection is inuse) when unselect a cohort
      setSelectedPids([])
    }
    setCheckedCohorts(checked)
  }, [
    checkedCohorts,
    selectedPids,
  ])

  const handleCreateView = useCallback((name, isPublic) => {
    const payload = {
      data_view: {
        name: name,
        private: !isPublic,
        data: viewDataFromState,
      },
    }
    return xFetch(Routes.project_data_visualizer_views_path(projectId), {
      method: 'POST',
      body: JSON.stringify(payload),
    }).then((data) => {
      toastr.info('View correctly created')
      setViews([...views, data])
      setCurrentViewId(data.id)
    }).catch((error) => {
      toastr.error(error.msgs.join('<br>'))
    })
  }, [
    views,
    viewDataFromState,
    projectId,
  ])

  const handleUpdateView = useCallback((name, isPublic) => {
    const payload = {
      data_view: {
        name: name,
        private: !isPublic,
        data: viewDataFromState,
      },
    }
    return xFetch(Routes.project_data_visualizer_view_path(projectId, currentViewId), {
      method: 'PATCH',
      body: JSON.stringify(payload),
    }).then((data) => {
      toastr.info('View correctly updated')
      setViews(views.map(v => v.id == currentViewId ? data : v))
    }).catch((error) => {
      toastr.error(error.msgs.join('<br>'))
    })
  }, [
    views,
    viewDataFromState,
    currentViewId,
    projectId,
  ])

  const handleDeleteView = useCallback(() => {
    return xFetch(Routes.project_data_visualizer_view_path(projectId, currentViewId), {
      method: 'DELETE',
    }).then(() => {
      toastr.info('View correctly deleted')
      setViews(_.reject(views, ['id', currentViewId]))
      setDefaultState()
    }).catch((error) => {
      toastr.error(error.msgs.join('<br>'))
    })
  }, [
    views,
    currentViewId,
    projectId,
    setDefaultState,
  ])

  const handleDuplicateView = useCallback(() => {
    const currentView = views.find(v => v.id == currentViewId)
    handleCreateView(`copy of ${currentView.name}`, false)
  }, [
    views,
    currentViewId,
    handleCreateView,
  ])

  const handleChangeView = useCallback((viewId) => {
    if (viewId && !_.isEmpty(views)) {
      let selectedView = views.find(v => v.id == viewId)

      if (!selectedView) {
        toastr.error(`Cannot find view with id ${viewId}`)
        return
      }

      if (selectedView.data) {
        applyView(selectedView)
      }
      else {
        setIsLoading(true)
        xFetch(Routes.project_data_visualizer_view_path(projectId, selectedView.id))
          .then((view) => {
            selectedView.data = view.data // save data for later selection
            setIsLoading(false)
            applyView(view)
          })
      }
    }
    else {
      setDefaultState()
    }
  }, [
    views,
    projectId,
    applyView,
    setDefaultState,
  ])

  const applyView = useCallback((view) => {
    setCurrentViewId(view.id)
    setCheckedNodes(view.data.checkedNodes)
    setExpandedNodes(view.data.expandedNodes)
    setCheckedCohorts(view.data.checkedCohorts)
    setSelectedPids(view.data.selectedPids)
    setLiftUpHistories(view.data.liftUpHistories)
    setOptions(view.data.options || {})
  }, [])

  const handleSelectedPidsChange = useCallback(selectedPids => setSelectedPids(selectedPids.toSorted()), [])

  const handleLiftUpHistoryChange = useCallback(({ liftUpHistory, cId }) => {
    setLiftUpHistories({ ...liftUpHistories, [cId]: liftUpHistory })
  }, [
    setLiftUpHistories,
    liftUpHistories,
  ])

  const handleOptionsChange = useCallback((options) => {
    setSelectedPids(options.useSelection ? (selectedPids || []) : undefined)
    setOptions(options)
  }, [selectedPids])

  const handleShowLegend = useCallback(cId => setShowLegendId(cId), [])

  const currentView = views.find(v => v.id == currentViewId)

  let currentViewChanges

  if (currentView) {
    currentViewChanges = diff(currentView.data, viewDataFromState)

    if (currentViewChanges) {
      console.debug('changes in currentView found:')
      consoleFormatter.log(currentViewChanges)
      toastr.warning('Remember to save it to keep changes', 'Current view has been changed!', { timeOut: 0, extendedTimeOut: 0, toastClass: 'toast view-changes' })
    }
    else {
      $('#toast-container .toast.view-changes').click()
    }
  }

  return (
    <WithSpinner isLoading={isLoading}>
      <CollectorLegendModal
        collector={showLegendId
          ? {
              legend: collectors[showLegendId]?.legend,
              title: collectors[showLegendId]?.data_model?.title,
            }
          : undefined}
        handleClose={() => handleShowLegend()}
      />
      <ViewsSelector
        currentView={currentView}
        isCurrentViewChangedContent={!!currentViewChanges}
        list={views}
        onCreate={_.isEmpty(checkedNodes) || _.isEmpty(checkedCohorts) ? undefined : handleCreateView}
        onDelete={handleDeleteView}
        onDuplicate={handleDuplicateView}
        onSelect={handleChangeView}
        onUpdate={handleUpdateView}
      />

      <Selector
        checkedCohorts={checkedCohorts}
        checkedNodes={checkedNodes}
        cohorts={cohorts}
        collectorTags={collectorTags}
        collectors={collectorDataModels}
        disabled={isLoading || (currentView && !currentView.manageable)}
        expandedNodes={expandedNodes}
        handleCohortCheckboxChange={handleCohortCheckboxChange}
        handleCollectorCheckboxChange={handleCollectorCheckboxChange}
        handleCollectorCheckboxExpand={handleCollectorCheckboxExpand}
        handleShowLegend={handleShowLegend}
        liftupHistoriesData={liftUpHistories}
      />
      <OptionsManager
        allPidTypes={allPidTypes}
        disabled={isLoading || (currentView && !currentView.manageable)}
        handleOptionsChange={handleOptionsChange}
        options={options}
        refPidType={refPidType}
      />
      {
        Object.keys(collectors).map(cId =>
          !_.isEmpty(checkedNodes[cId]) && !_.isEmpty(checkedCohorts)
            ? (
                <Visualizer
                  checkedCohorts={checkedCohorts}
                  currentView={currentView}
                  fieldNameRegexp={fieldNameRegexp}
                  forceEncryptionOnExport={forceEncryptionOnExport}
                  handleLiftUpHistoryChange={handleLiftUpHistoryChange}
                  handleSelectedPidsChange={handleSelectedPidsChange}
                  isCurrentViewChangedContent={!!currentViewChanges}
                  key={cId}
                  liftUpHistoryData={liftUpHistories[cId]}
                  model={collectors[cId].data_model}
                  nodeIds={checkedNodes[cId]}
                  onlyRead={currentView ? !currentView.manageable : null}
                  options={options}
                  refPidType={refPidType}
                  privileges={privileges}
                  projectId={projectId}
                  selectedPids={selectedPids}
                  workbook={collectors[cId].workbook}
                />
              )
            : null
        )
      }
    </WithSpinner>
  )
}

ProjectDataVisualizer.propTypes = {
  cohorts: PropTypes.array.isRequired,
  collectors: PropTypes.object.isRequired,
  privileges: PropTypes.object.isRequired,
  projectId: PropTypes.number.isRequired,
  viewId: PropTypes.number,
  refPidType: PropTypes.string.isRequired,
  allPidTypes: PropTypes.array,
  fieldNameRegexpString: PropTypes.string.isRequired,
}
