import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, Form, InputGroup, FormControl } from 'react-bootstrap'
import { CopyToClipboardButton } from '../../utils'

function DownloadWidgetModal({
  handleClose,
  show = false,
  currentSheetName,
  handleDownload,
  forceEncryption,
}) {
  const [downloadAll, setDownloadAll] = useState(false)
  const [format, setFormat] = useState('excel')
  const [password, setPassword] = useState()
  const [usePassword, setUsePassword] = useState(forceEncryption)
  const [showPassword, setShowPassword] = useState(false)

  function handleSubmit() {
    handleDownload({
      downloadAll: downloadAll,
      format: format,
      password: password,
    })
    setUsePassword()
    setShowPassword()
  }

  function handleCancel() {
    handleClose(false)
    setUsePassword()
    setShowPassword()
  }

  function handleChangeWhat(e) {
    setDownloadAll(e.target.value == 'true')
  }

  function handleChangeFormat(e) {
    setFormat(e.target.value)
  }

  function handleChangeUsePassword(e) {
    setUsePassword(e.target.checked)
  }

  useEffect(() => {
    setPassword(usePassword ? _.random(0, 9999999) : undefined)
  }, [usePassword])

  return (
    <Modal
      onHide={handleCancel}
      show={show}
    >
      <Modal.Header>
        <Modal.Title>
          <span>
            <i className="fas fa-download" />
            {' '}
            Download
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>

          <Form.Group>
            <fieldset>
              <Form.Check
                checked={downloadAll}
                id="downloadAll-true"
                label="All sheets"
                name="what"
                onChange={handleChangeWhat}
                type="radio"
                value={true}
              />
              <Form.Check
                checked={!downloadAll}
                id="downloadAll-false"
                label={`Only current sheet (${currentSheetName})`}
                name="what"
                onChange={handleChangeWhat}
                type="radio"
                value={false}
              />
            </fieldset>
          </Form.Group>

          <Form.Group>
            <Form.Label as="legend">Format</Form.Label>
            <fieldset>
              <Form.Check
                checked={format == 'excel'}
                id="format-excel"
                label="Excel"
                name="format"
                onChange={handleChangeFormat}
                type="radio"
                value="excel"
              />
              <Form.Check
                checked={format == 'csv'}
                id="format-csv"
                label="CSV"
                name="format"
                onChange={handleChangeFormat}
                type="radio"
                value="csv"
              />
            </fieldset>
          </Form.Group>

          <Form.Group>
            <Form.Label as="legend">Protection</Form.Label>
            <fieldset>
              <Form.Check
                checked={usePassword}
                disabled={forceEncryption}
                id="usePassword"
                label="Protect file with password"
                name="usePassword"
                onChange={handleChangeUsePassword}
                type="checkbox"
              />
              {
                usePassword
                  ? (
                      <Form.Group>
                        <InputGroup>
                          <FormControl
                            aria-describedby="basic-addon2"
                            autoComplete="off"
                            defaultValue={password}
                            readOnly
                            type={showPassword ? 'text' : 'password'}
                          />
                          <InputGroup.Append>
                            <Button
                              onClick={() => setShowPassword(!showPassword)}
                              title="show/hide password"
                              variant="outline-secondary"
                            >
                              <i className={`fas fa-eye${showPassword ? '-slash' : ''}`} />
                            </Button>
                            <CopyToClipboardButton
                              text={password}
                              variant="outline-secondary"
                            />
                          </InputGroup.Append>
                        </InputGroup>
                        <Form.Text id="passwordHelpInline" muted>
                          Please annotate file password now. It will never be shown again
                        </Form.Text>
                      </Form.Group>
                    )
                  : null
              }
            </fieldset>
          </Form.Group>

        </Form>

      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Button
          onClick={handleSubmit}
          title="Download"
          variant="primary"
        >
          {
            usePassword
              ? 'I annotate the password, download!'
              : 'Download'
          }
        </Button>
        <Button
          onClick={handleCancel}
          variant="secondary"
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

DownloadWidgetModal.propTypes = {
  show: PropTypes.bool.isRequired,
  forceEncryption: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleDownload: PropTypes.func.isRequired,
  currentSheetName: PropTypes.string.isRequired,
}

export default DownloadWidgetModal
