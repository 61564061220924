import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip, OverlayTrigger, Button } from 'react-bootstrap'
import { bootstrapSelectStyle, CopyToClipboardButton, usertimestampedBy } from '../utils'
import Select from 'react-select'

export default class Sample extends React.Component {
  static propTypes = {
    onDelete: PropTypes.func,
    onHandleRemoveForm: PropTypes.func,
    onHandleAddForm: PropTypes.func,
    loading: PropTypes.bool.isRequired,
    forms: PropTypes.array.isRequired,
    sample: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      form_ids: props.sample.form_ids,
    }
  }

  handleFormsChange = (val, event) => {
    switch (event.action) {
      case 'select-option':
        this.props.onHandleAddForm(event.option.value)
        break
      case 'remove-value':
        this.props.onHandleRemoveForm(event.removedValue.value)
        break
    }
  }

  render() {
    const formOptions = this.props.forms.map((c) => {
      return {
        value: c.id.toString(),
        label: c.name,
      }
    })

    return (
      <div className="small-box bg-yellow">
        <div className="inner">
          <h5 className="id">
            {this.props.sample.code}
            {' '}
            <small>{moment(this.props.sample.date).format()}</small>
          </h5>
          <Select
            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
            isClearable={true}
            isMulti={true}
            onChange={this.handleFormsChange}
            options={formOptions}
            placeholder="forms that will use this sample ..."
            styles={bootstrapSelectStyle}
            value={formOptions.filter(o => _.includes(this.props.sample.form_ids, o.value))}
          />
          <div className="icon">
            <i className="fa fa-flask" />
          </div>
        </div>
        <div className="small-box-footer text-left pl-1">
          <small>
            {usertimestampedBy(this.props.sample.created_at, this.props.sample.creator, 'created')}
          </small>
          <div className="text-right pr-1">
            <div className="btn-group ">
              <CopyToClipboardButton
                size="sm"
                text={this.props.sample.code}
                variant="default"
              />
              {
                this.props.onDelete
                  ? (
                      <Button
                        disabled={this.props.loading}
                        onClick={() => this.props.onDelete(this.props.sample.code)}
                        size="sm"
                        title="delete this sample"
                        variant="danger"
                      >
                        <i className="fa fa-trash" />
                      </Button>
                    )
                  : null
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}
