import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'

export default class Navigator extends React.PureComponent {
  static propTypes = {
    handleChangePage: PropTypes.func.isRequired,
    pages: PropTypes.array.isRequired,
    currentPageNo: PropTypes.number.isRequired,
  }

  goToPage = (event) => {
    this.props.handleChangePage(Number(event.target.value))
  }

  goToPreviousPage = () => {
    this.props.handleChangePage(this.props.currentPageNo - 1)
  }

  goToNextPage = () => {
    this.props.handleChangePage(this.props.currentPageNo + 1)
  }

  render() {
    // console.debug("render Navigator")

    return (
      <div className="input-group">
        <span className="input-group-prepend">
          { this.props.currentPageNo > 0
            ? (
              <Button onClick={this.goToPreviousPage} size="sm" variant="outline-secondary">
                <i className="mr-2 ml-2 fa fa-chevron-left" />
              </Button>
              )
            : null}
        </span>
        <select className="custom-select" onChange={this.goToPage} value={this.props.currentPageNo}>
          {this.props.pages.map((pageTitle, i) => (
            <option key={i} value={i}>
              {i + 1}
              :
              {' '}
              {pageTitle}
            </option>
          ))}
        </select>
        <span className="input-group-append">
          { this.props.currentPageNo < this.props.pages.length - 1
            ? (
              <Button onClick={this.goToNextPage} size="sm" variant="outline-secondary">
                <i className="mr-2 ml-2 fa fa-chevron-right" />
              </Button>
              )
            : null}
        </span>
      </div>
    )
  }
}
