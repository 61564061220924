import React from 'react'
import { ReactQuestionFactory, SurveyQuestionDropdownBase } from 'survey-react-ui'
import { ItemValue, Serializer } from 'survey-core'
import Select, { components } from 'react-select'
import Creatable from 'react-select/creatable'
import { bootstrapSelectStyle } from '../../components/utils'

const props = [
  {
    name: 'keepIncorrectValues',
    type: 'boolean',
    default: false,
    onGetValue: function (obj) {
      return obj.canAdd || obj.getPropertyValue('keepIncorrectValues', false)
    },
  },
  {
    name: 'canAdd',
    type: 'boolean',
    default: false,
  },
  {
    name: 'markedOptions',
    default: [],
  },
]

Serializer.addProperties('dropdown', props)
Serializer.addProperties('checkbox', props)

function MarkedMultiValueLabel(props) {
  return (
    <components.MultiValueLabel {...props}>
      <span>
        {props.children}
        <sup>
          {props.noteNum}
        </sup>
      </span>
    </components.MultiValueLabel>
  )
}

const collectorStyle = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    minHeight: '34px',
    borderRadius: '4px',
    borderColor: 'var(--sjs-border-light)',
  }),
  valueContainer: (baseStyles, state) => ({
    ...baseStyles,
    padding: '0px 8px',
  }),
  dropdownIndicator: (baseStyles, state) => ({
    ...baseStyles,
    padding: '6px',
  }),
  clearIndicator: (baseStyles, state) => ({
    ...baseStyles,
    padding: '6px',
  }),
  menuPortal: (baseStyles, state) => ({
    ...baseStyles,
    zIndex: 2000,
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    minWidth: 'fit-content',
  }),
}

class ReactSelectDropdown extends SurveyQuestionDropdownBase {
  constructor(props) {
    super(props)
    this.isMulti = props.question.getType() == 'checkbox'

    // force disabling of showOtherItem functionality: it's implemented by the canAdd option
    props.question.storeOthersAsComment = false
    props.question.showOtherItem = false

    if (props.question.canAdd) {
      this.updateWithCustomChoices()
      props.question.valueChangedCallback = this.updateWithCustomChoices
      props.question.registerPropertyChangedHandlers(['visibleChoices'], this.updateWithCustomChoices)
    }
    props.question.registerPropertyChangedHandlers(['visibleChoices'], this.updateOptions)

    // hack question renderedValue which is used by dependent question choices setup
    // override rendredValueFromData so that return ALWAYS defalut "store other" mechanism is bypassed
    props.question.rendredValueFromData = function (val) {
      return val
    }
    // set renderedValue as soon as question widget is being applied. This is need only when loading question with pre-existing data
    // props.question.setPropertyValue('renderedValue', props.question.rendredValueFromData(props.question.value))

    this.state = {
      options: this.getOptions(),
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount()
    this.props.question.unregisterPropertyChangedHandlers(['visibleChoices'])
  }

  handleChange = arg => this.isMulti
    ? this.props.question.value = _.map(arg, 'value')
    : this.props.question.value = arg?.value

  handleCreate = arg => this.isMulti
    ? this.props.question.value = [...this.props.question.value, arg]
    : this.props.question.value = arg

  getOptions = () => {
    return this.props.question.visibleChoices.map(function (choice) {
      return {
        value: choice.value,
        label: choice.text,
      }
    })
  }

  updateWithCustomChoices = () => {
    // console.debug('updateWithCustomChoices question: ' + this.props.question.name)
    // add custom choices to predefined if they are included in value
    if (this.props.question.value) {
      let allValues = this.props.question.visibleChoices.map(c => c.value)
      let addedValues = _.flatten([this.props.question.value]).filter(v => !allValues.includes(v)).map(v => new ItemValue(v))
      if (addedValues.length) {
        this.props.question.setPropertyValue('visibleChoices', [...this.props.question.visibleChoices].concat(addedValues))
      }
    }
  }

  updateOptions = () => this.setState({ options: this.getOptions() })

  getSelectedOption = () => this.isMulti
    ? this.getOptions().filter(o => _.includes(this.props.question.value || [], o.value))
    : this.getOptions().find(o => o.value == this.props.question.value)

  renderElement() {
    // console.debug('Rendering question: ' + this.props.question.name)
    const ReactSelect = this.props.question.canAdd ? Creatable : Select
    return (
      <ReactSelect
        classNamePrefix="react-select"
        className="react-select"
        components={{
          // DropdownIndicator: () => null,
          // IndicatorSeparator: () => null,
          MultiValueLabel: this.props.question.markedOptions ? props => MarkedMultiValueLabel({ ...props, noteNum: this.props.question.markedOptions[props.data.value] }) : components.MultiValueLabel,
        }}
        hideSelectedOptions={false}
        isClearable={this.props.question.allowClear}
        isDisabled={this.props.question.isReadOnly}
        isMulti={this.isMulti}
        isSearchable={this.props.question.searchEnabled}
        menuPosition="fixed"
        onChange={this.handleChange}
        onCreateOption={this.handleCreate}
        options={this.state.options}
        placeholder={this.props.question.placeholder || 'Please select ...'}
        styles={_.includes(this.props.question.survey.rootCss, 'bootstrap') ? bootstrapSelectStyle : collectorStyle}
        value={this.getSelectedOption() || null} // react-select need null to clear the input field
      />
    )
  }
}

ReactQuestionFactory.Instance.registerQuestion(
  'sv-reactselect',
  function (props) {
    return React.createElement(ReactSelectDropdown, props)
  }
)
