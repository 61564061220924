import React from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, ButtonGroup } from 'react-bootstrap'

function DatabaseWidgetModal({
  handleDestroyDb,
  handleClose,
  show = false,
  exportDbData,
  isLoading,
  handleRefresh,
  handleSyncDbNow,
}) {
  function detroyDb() {
    handleDestroyDb()
    handleClose(false)
  }

  function handleCancel() {
    handleClose(false)
  }

  return (
    <Modal
      onHide={handleCancel}
      show={show}
    >
      <Modal.Header>
        <Modal.Title>
          <span>
            <i className="fa fa-database" />
            {' '}
            Exported database properties
          </span>
          <br />
          {
            exportDbData.success
              ? (
                  <small>
                    last sync:
                    {moment(exportDbData.updated_at).format('DD/MM/YYYY HH:mm:ss')}
                  </small>
                )
              : null
          }
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {
          exportDbData.is_ready
            ? exportDbData.success
              ? (
                  <pre>
                    <code>
                      {JSON.stringify(exportDbData.database_params, null, 2)}
                    </code>
                  </pre>
                )
              : (
                  <span>
                    There was a problem while building the database.
                    <br />
                    <strong>{exportDbData.last_outcome_message}</strong>
                  </span>
                )
            : (
                <p className="text-center">
                  <strong>Database is under construction.</strong>
                  <br />
                  {
                    isLoading
                      ? 'checking now ...'
                      : (
                          <span className="ml-1">
                            Please try later or
                            {' '}
                            <a href="#" onClick={handleRefresh}>check now</a>
                          </span>
                        )
                  }
                </p>
              )
        }

      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        {
          exportDbData.is_ready
            ? (
                <div>
                  <ButtonGroup>
                    <Button
                      disabled={isLoading || !exportDbData.success}
                      onClick={handleSyncDbNow}
                      title="sync database"
                      variant="outline-warning"
                    >
                      <span>
                        <i className="fa fa-sync" />
                        {' '}
                        sync database
                      </span>
                    </Button>
                    <Button
                      disabled={isLoading}
                      onClick={detroyDb}
                      title="destroy database"
                      variant="outline-danger"
                    >
                      <span>
                        <i className="fa fa-trash" />
                        {' '}
                        destroy database
                      </span>
                    </Button>
                  </ButtonGroup>
                  {
                    isLoading
                      ? <span className="ml-2"><i className="fas fa-spinner fa-spin" /></span>
                      : null
                  }
                </div>
              )
            : null
        }
        <Button
          onClick={handleCancel}
          variant="secondary"
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

DatabaseWidgetModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSyncDbNow: PropTypes.func.isRequired,
  handleDestroyDb: PropTypes.func.isRequired,
  handleRefresh: PropTypes.func.isRequired,
  exportDbData: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
}

export default DatabaseWidgetModal
