import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { bootstrapSelectStyle, xFetch } from '../components/utils'

function SelectLocaleWidget({
  localeLabels,
  locale,
}) {
  const
    options = _.map(localeLabels, (label, value) => ({ value: value, label: label })),
    currentLocale = { value: locale, label: localeLabels[locale] }

  const setLocale = (locale) => {
    xFetch(Routes.save_preferred_locale_path(locale.value)).then(() => window.location.reload())
  }

  return (
    <Select
      components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
      menuPlacement="top"
      onChange={setLocale}
      options={options}
      styles={bootstrapSelectStyle}
      value={currentLocale}
    />
  )
}

SelectLocaleWidget.propTypes = {
  localeLabels: PropTypes.object.isRequired,
  locale: PropTypes.string,
}

export default SelectLocaleWidget
