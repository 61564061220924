import React, { useState, useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Button, ButtonGroup } from 'react-bootstrap'
import Steps from 'rsuite/Steps'
import 'rsuite/Steps/styles/index.css'
import { Provider } from './MultiStepFormContext'
import SjsBuilder from './collector_form/SjsBuilder'
import { xFetch, Userstamp, addDeletionsToDataForRails } from '../utils'
import StepForm from './step_form'

const updateAddressBar = (step) => {
  let newUrl = new URL(window.location)
  newUrl.searchParams.set('step', step + 1)
  history.pushState({}, null, newUrl)
}

const stepsData = [
  {
    attrs: ['type', 'name', 'description', 'tag_list', 'version_id'],
    questions: ['type', 'name', 'description', 'tag_list', 'version_id'],
  },
  {
    attrs: ['model'],
    questions: ['model'],
  },
  {
    attrs: ['acl', 'cc_acl', 'owner_id'],
    questions: ['sharingboard', 'contentsharingboard', 'ownership'],
  },
]

function CollectorForm({
  sjsModel,
  initialData,
  actionPaths,
  resourceName,
  resourceHumanName,
  cardLayout,
  afterSaveAction,
  initialStep,
}) {
  const [data, setData] = useState(initialData)
  const [step, setStep] = React.useState(initialStep ? parseInt(initialStep) - 1 : 0)
  const [status, setStatus] = React.useState()
  const [errors, setErrors] = React.useState()

  const resourceDisplayName = useMemo(() => resourceHumanName || _.capitalize(resourceName),
    [
      resourceName,
      resourceHumanName,
    ]
  )

  const data_changed = useMemo(() => {
    return !_.isEqual(initialData, data)
  }, [
    JSON.stringify(data),
  ])

  const errorsByStep = useMemo(() => {
    return _.map(stepsData, (data) => {
      return _.isEmpty(errors)
        ? []
        : _.map(_.pick(errors.msgs[0], data.attrs), (error, attr) => `${attr} ${error}`)
    })
  }, [
    errors,
  ])

  useEffect(() => {
    if (data_changed) {
      setStatus('changed')
    }
    else {
      setStatus('saved')
    }
  }, [data])

  useEffect(() => {
    updateAddressBar(step)
  }, [step])

  const extractSjsModel = useCallback(
    (elements) => {
      let model = _.cloneDeep(sjsModel)
      model.elements = _.filter(sjsModel.elements, e => elements.includes(e.name))
      return model
    },
    [
      sjsModel,
    ]
  )

  const extractSjsData = useCallback(
    dataFields => _.pick(data, dataFields),
    [
      data,
    ]
  )

  const updateData = useCallback(
    stepFormData => setData({ ...data, ...stepFormData }),
    [
      data,
    ]
  )

  const reset = useCallback(
    () => {
      setData(initialData)
      setErrors()
      setStep(0)
    },
    [
      data,
    ]
  )

  const fetchErrors = useCallback(
    () => {
      const payload = {
        [resourceName]: addDeletionsToDataForRails(data, initialData),
      }
      return xFetch(Routes.validate_collectors_path(), {
        method: 'POST',
        body: JSON.stringify(payload),
      })
    },
    [
      data,
      initialData,
      resourceName,
    ]
  )

  const onChangeStep = (nextStep) => {
    fetchErrors()
      .then(() => {
        // console.debug(`${resourceDisplayName} is valid`)
        setErrors()
        setStep(nextStep < 0 ? 0 : nextStep > 2 ? 2 : nextStep)
      }).catch((errors) => {
        setErrors(errors)
        toastr.error(`${resourceDisplayName} cannot be savehas some problems. Check for errors`)
      })
  }
  const onNext = () => onChangeStep(step + 1)
  const onPrevious = () => onChangeStep(step - 1)

  const onSave = () => {
    const payload = {
      [resourceName]: addDeletionsToDataForRails(data, initialData),
    }

    let headers = {}
    if (!afterSaveAction) {
      headers['X-will-redirect'] = true
    }

    setStatus('saving')
    // console.debug('about to send -> ' + JSON.stringify(payload))
    xFetch(actionPaths.save, {
      method: (initialData.id ? 'PATCH' : 'POST'),
      headers: headers,
      body: JSON.stringify(payload),
    })
      .then((body) => {
        if (afterSaveAction) {
          afterSaveAction(body)
        }
        else {
          body.path ? window.location = body.path : window.location.reload()
        }
      })
      .catch((errors) => {
        setErrors(errors)
        setStatus('changed')
        toastr.error(`${resourceDisplayName} cannot be saved. Check for errors`)
      })
  }

  return (
    <div className={cardLayout ? 'card card-default mb-4' : 'mb-4'}>
      <div className={cardLayout ? 'card-body' : ''}>
        <Provider value={{ data, updateData, extractSjsModel, extractSjsData, sjsModel, errors }}>
          <div className="mb-2 text-right">
            <ButtonGroup size="sm">
              <Button
                disabled={step === 0}
                onClick={onPrevious}
                variant="link"
              >
                Previous
              </Button>
              <Button
                disabled={(!data.id && step < 1) || step === 2}
                onClick={onNext}
                variant="link"
              >
                Next
              </Button>
            </ButtonGroup>
          </div>
          <Steps current={step}>

            <Steps.Item
              className={step == 0 ? 'font-weight-bold' : undefined}
              status={errorsByStep[0].length ? 'error' : undefined}
              title={(
                <Button
                  className={`${errorsByStep[0].length ? 'text-danger' : 'text-body'} ${step == 0 ? 'font-weight-bold' : undefined}`}
                  onClick={() => onChangeStep(0)}
                  style={{ padding: 0 }}
                  variant="link"
                >
                  General
                </Button>
              )}
            />

            <Steps.Item
              className={step == 1 ? 'font-weight-bold' : undefined}
              icon={data.id ? undefined : <i className="fas fa-lock" />}
              status={errorsByStep[1].length ? 'error' : undefined}
              title={(
                <Button
                  className={`${errorsByStep[1].length ? 'text-danger' : 'text-body'} ${step == 1 ? 'font-weight-bold' : undefined}`}
                  disabled={!data.id}
                  onClick={() => onChangeStep(1)}
                  style={{ padding: 0 }}
                  variant="link"
                >
                  Model
                </Button>
              )}
            />

            <Steps.Item
              className={step == 2 ? 'font-weight-bold' : undefined}
              icon={data.id ? null : <i className="fas fa-lock" />}
              status={errorsByStep[2].length ? 'error' : undefined}
              title={(
                <Button
                  className={`${errorsByStep[2].length ? 'text-danger' : 'text-body'} ${step == 2 ? 'font-weight-bold' : undefined}`}
                  disabled={!data.id}
                  onClick={() => onChangeStep(2)}
                  style={{ padding: 0 }}
                  variant="link"
                >
                  Sharing
                </Button>
              )}
            />

          </Steps>
          <main>
            <StepForm
              {...stepsData[step]}
              errors={errorsByStep[step]}
            />
          </main>
          <div className="text-right">
            {
              actionPaths.save
                ? (
                  <ButtonGroup>
                    <Button
                      disabled={!data_changed}
                      onClick={reset}
                      variant="link"
                    >
                      <i className="fas fa-undo-alt" />
                      Undo Changes
                    </Button>
                    <Button
                      bsPrefix={`btn btn-primary btn-sjs-save ${status} mr-2`}
                      disabled={!data_changed}
                      onClick={onSave}
                      variant="primary"
                    />
                  </ButtonGroup>
                  )
                : null
              }
          </div>
        </Provider>

      </div>
      <div className="card-footer mt-2">
        <Userstamp {..._.pick(initialData, ['created_at', 'creator', 'updated_at', 'updater'])} />
      </div>
    </div>
  )
}

CollectorForm.propTypes = {
  sjsModel: PropTypes.object.isRequired,
  initialData: PropTypes.object.isRequired,
  actionPaths: PropTypes.object.isRequired,
  resourceName: PropTypes.string.isRequired,
  resourceHumanName: PropTypes.string,
  cardLayout: PropTypes.bool,
  afterSaveAction: PropTypes.func,
}

export default CollectorForm
