import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import {
  filterFns,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getSortedRowModel,
  getFilteredRowModel,
} from '@tanstack/react-table'
import { Table, Badge, DropdownButton, Dropdown, Button } from 'react-bootstrap'
import styled from 'styled-components'
import * as ColumnFilter from './sheet/column_filters'

// need to be placed outside the function Component otherwise it will be redefined on every run cousing a complete remount
const Styles = styled.div`
  .table-responsive {
    max-height: 70vh;
    text-overflow: ellipsis;
    white-space: nowrap;
    table {
      height: 100%;
      thead tr th {
        position: sticky;
        top: 0;
        cursor: pointer;
        background-color: #E6E6E6;
      }
      tbody {
        tr {
          &.filtered {
            opacity: .4;
          }
          td {
            font-family: 'monaco';
            background-color: #f5f5f5;
            &.pid-col {
              background-color: #d7eed7;
            }
            &.foreign-key-col {
              background-color: #d0ecef;
            }
            &.sequential-foreign-key-col {
              background-color: #f5f5dc;
            }
            &.lifted-up-col {
              background-color: #e4e4f7;
            }
            &.info-col {
              background-color: #ffefd5;
            }
          }
          &:hover {
            td {
              background-color: white;
              &.pid-col {
                background-color: #d7eed730;
              }
              &.foreign-key-col {
                background-color: #d0ecef30;
              }
              &.sequential-foreign-key-col {
                background-color: #f5f5dc30;
              }
              &.lifted-up-col {
                background-color: #e4e4f730;
              }
              &.info-col {
                background-color: #ffefd530;
              }
            }
          }
        }
      }
    }
  }
`

const getTypeBasedClassNames = ({ type, contentType }) => {
  let res = []
  if (contentType == 'integer' || contentType == 'float' || contentType == 'boolean' || contentType == 'date') {
    res.push('text-right')
  }

  switch (type) {
    case 'pId':
      res.push('pid-col')
      break
    case 'fKey':
      res.push('foreign-key-col')
      break
    case 'sfKey':
    case 'spKey':
      res.push('sequential-foreign-key-col')
      break
    case 'liftedUp':
      res.push('lifted-up-col')
      break
    case 'info':
      res.push('info-col')
      break
    default:
  }
  return res.join(' ')
}

function IndeterminateCheckbox({
  indeterminate,
  className = '',
  ...rest
}) {
  const ref = React.useRef(null)

  React.useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate
    }
  }, [
    ref,
    indeterminate,
    rest.checked,
  ])

  return (
    <input
      className={className + ' cursor-pointer'}
      ref={ref}
      type="checkbox"
      {...rest}
    />
  )
}

const Filter = (props) => {
  switch (props.column.columnDef.typeInfo.contentType) {
    case 'integer':
    case 'float':
      return ColumnFilter.NumberRange(props)
    case 'boolean':
      return ColumnFilter.BooleanSelect(props)
    default:
      return ColumnFilter.StringSearch(props)
  }
}

const columnHelper = createColumnHelper()

const getFilterFunction = (field) => {
  switch (field.contentType) {
    case 'integer':
    case 'float':
      return 'inNumberRange'
    case 'boolean':
      return 'equals'
    default:
      return 'includesString'
  }
}

function Sheet({
  fields,
  keyName,
  values,
  selectedPids,
  showOnlySelectedPids,
  sheetState,
  refPidType,
  handleSheetStateChange,
  handleSelectedPidsChange,
  disableRowSelection,
  handleGoToContent,
  filtersEnabled = false,
}) {
  // console.debug('rendering Sheet')

  const globalFilterFunction = useCallback((row, columnId, filterValue) => showOnlySelectedPids && filterValue.includes(row.original[refPidType]), [
    showOnlySelectedPids,
    refPidType,
  ])

  const columns = useMemo(() => {
    let selectionColumn = showOnlySelectedPids || _.isUndefined(selectedPids)
      ? undefined
      : {
          id: '_select',
          customSize: 20,
          header: ({ table }) => {
            const filteredPids = _.uniq(_.map(table.getFilteredRowModel().rows, `original.${refPidType}`))
            const selectedAmongFilteredPids = _.intersection(filteredPids, selectedPids)
            return (
              <IndeterminateCheckbox
                checked={selectedAmongFilteredPids.length ? selectedAmongFilteredPids.length == filteredPids.length : null}
                disabled={disableRowSelection}
                indeterminate={selectedAmongFilteredPids.length ? selectedAmongFilteredPids.length < filteredPids.length : null}
                onChange={(e) => {
                  handleSelectedPidsChange(e.target.checked ? _.union(selectedPids, filteredPids) : _.difference(selectedPids, filteredPids))
                }}
              />
            )
          },
          cell: ({ row }) => {
            const rowPid = row.original[refPidType]
            return (
              <IndeterminateCheckbox
                checked={_.includes(selectedPids, rowPid)}
                disabled={disableRowSelection}
                onChange={(e) => {
                  handleSelectedPidsChange(e.target.checked ? _.union(selectedPids, [rowPid]) : _.difference(selectedPids, [rowPid]))
                }}
              />
            )
          },
          enableColumnFilter: false,
        }

    let actionsColumn = {
      id: '_actions',
      customSize: 20,
      cell: ({ row }) => {
        const rowPid = row.original[refPidType]
        return (
          <a onClick={() => handleGoToContent(rowPid)} title="Go to content">
            <i className="fas fa-file-invoice" />
          </a>
        )
      },
      enableColumnFilter: false,
    }

    return _.compact(
      [
        selectionColumn,
        actionsColumn,
        ..._.map(fields, f =>
          columnHelper.accessor(f.name.replaceAll('.', '_'), {
            header: (
              <strong>
                {f.name}
                {f.name == keyName ? <sup className="text-danger">KEY</sup> : ''}
              </strong>
            ),
            cell: (info) => {
              const v = info.getValue()
              if (_.isNil(v)) {
                return null
              }
              else {
                return _.isBoolean(v) ? v.toString() : v
              }
            },
            typeInfo: f,
            filterFn: getFilterFunction(f),
            enableColumnFilter: filtersEnabled,
          })
        ),
      ]
    )
  }, [
    fields,
    selectedPids,
    filtersEnabled,
    showOnlySelectedPids,
    disableRowSelection,
    handleGoToContent,
    handleSelectedPidsChange,
    keyName,
    refPidType,
  ])

  const table = useReactTable({
    data: values,
    columns,
    initialState: sheetState,
    sortDescFirst: false,
    enableMultiSort: true,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    isMultiSortEvent: e => e.metaKey,
    enableGlobalFilter: selectedPids && showOnlySelectedPids,
    globalFilterFn: globalFilterFunction,
  })

  // Manage your own state
  const [state, setState] = React.useState(table.initialState)

  // Override the state managers for the table to your own
  table.setOptions(prev => ({
    ...prev,
    state: {
      ...state,
      globalFilter: prev.enableGlobalFilter ? selectedPids : undefined,
    },
    onStateChange: setState,
    // onRowSelectionChange: setRowSelection,
    // These are just table options, so if things
    // need to change based on your state, you can
    // derive them here

    // Just for fun, let's debug everything if the pageIndex
    // is greater than 2
    // debugTable: state.pagination.pageIndex > 2,
  }))

  const preFilteredRows = table.getPreFilteredRowModel().rows

  const filteredRows = table.getFilteredRowModel().rows

  // const getPids = useCallback(rows => _.uniq(_.map(rows, `original.${refPidType}`)), [refPidType])

  // console.debug(`============================`)
  // console.debug('preFilteredRows', preFilteredRows.length)
  // console.debug('filteredRows', filteredRows.length)
  // console.debug(`============================`)

  React.useEffect(() => {
    // console.debug('Sheet useEffect state CHANGED ')
    handleSheetStateChange({ ..._.pick(state, ['sorting', 'pagination', 'columnFilters']), filteredRowsCount: filteredRows.length })
  }, [state.sorting, state.pagination, state.columnFilters])

  React.useEffect(() => {
    // console.debug('Sheet useEffect fields visibility CHANGED')
    setState({ ...state, columnVisibility: _.filter(fields, 'hidden').reduce((res, k) => ({ ...res, [k.id]: false }), {}) })
  }, [_.filter(fields, 'hidden').length])

  // console.debug(JSON.stringify(table.getState()))
  return (
    <Styles>
      <Table bordered responsive size="sm">
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th
                  className="align-top"
                  colSpan={header.colSpan}
                  key={header.id}
                  style={{
                    width: header.column.columnDef.customSize || undefined,
                  }}
                >
                  {header.isPlaceholder
                    ? null
                    : (
                        <>
                          <div className="d-flex justify-content-between">
                            <div className="mr-2" onClick={header.column.getToggleSortingHandler()}>
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                              {{
                                asc: ' ▲',
                                desc: ' ▼',
                              }[header.column.getIsSorted()] ?? null}
                            </div>
                            {
                              header.column.getCanFilter()
                                ? (
                                    <Button active={header.column.getIsFiltered()} bsPrefix="btn btn-link p-0" disabled={!header.column.getIsFiltered()} onClick={() => header.column.setFilterValue()}>
                                      <i className="fa fa-filter" />
                                    </Button>
                                  )
                                : null
                            }
                          </div>
                          <div className="d-flex">
                            {
                              header.column.getCanFilter() && table.getPreFilteredRowModel().rows.length
                                ? <Filter column={header.column} table={table} />
                                : null
                            }
                          </div>
                        </>
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map(row => (
            <tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <td
                  className={cell.column.columnDef.typeInfo ? getTypeBasedClassNames(cell.column.columnDef.typeInfo) : null}
                  key={cell.id}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="bg-light p-2 mb-2 d-flex justify-content-between">
        <div>
          <span>
            {
              table.getRowModel().rows.length < preFilteredRows.length
                ? `Showing ${table.getRowModel().rows.length} of ${preFilteredRows.length}`
                : `Showing all ${table.getRowModel().rows.length} rows`
            }
          </span>
        </div>
        <div>
          {
            table.getRowModel().rows.length < values.length
              ? (
                  <ul className="pagination pagination-sm mb-0">
                    <li className={'page-item' + (table.getCanPreviousPage() ? '' : ' disabled')}>
                      <a className="page-link" onClick={() => table.setPageIndex(0)}>
                        <i className="fa fa-fast-backward" />
                      </a>
                    </li>
                    <li className={'page-item' + (table.getCanPreviousPage() ? '' : ' disabled')}>
                      <a className="page-link" onClick={() => table.previousPage()}>
                        <i className="fa fa-backward" />
                      </a>
                    </li>
                    <li className="page-item disabled">
                      <span className="page-link">
                        {`page ${table.getState().pagination.pageIndex + 1} of ${table.getPageCount()}`}
                      </span>
                    </li>
                    <li className={'page-item' + (table.getCanNextPage() ? '' : ' disabled')}>
                      <a className="page-link" onClick={() => table.nextPage()}>
                        <i className="fa fa-forward" />
                      </a>
                    </li>
                    <li className={'page-item' + (table.getCanNextPage() ? '' : ' disabled')}>
                      <a className="page-link" onClick={() => table.setPageIndex(table.getPageCount() - 1)}>
                        <i className="fa fa-fast-forward" />
                      </a>
                    </li>
                  </ul>
                )
              : null
          }
        </div>
        <DropdownButton size="xs" title={`${table.getState().pagination.pageSize} per page`} variant="outline-secondary">
          {[10, 30, 50, 100, 500, 1000].map(pageSize => (
            <Dropdown.Item
              key={pageSize}
              onClick={() => {
                table.setPageSize(pageSize)
                table.setPageIndex(0)
              }}
            >
              {pageSize}
            </Dropdown.Item>
          )
          )}
        </DropdownButton>
      </div>
      <div className="bg-light p-2 mb-2 d-flex justify-content-between">
        <p className="mb-0">
          <span className="h5">
            {
              selectedPids && selectedPids.length
                ? (
                    <Badge pill variant="success">
                      Selected
                    </Badge>
                  )
                : null
            }
            {
              filtersEnabled
                ? (
                    <Badge pill variant="info">
                      Filtered
                    </Badge>
                  )
                : null
            }
            {
              !showOnlySelectedPids
                ? (
                    <Badge pill variant="secondary">
                      Total
                    </Badge>
                  )
                : null
            }
          </span>
        </p>
      </div>

    </Styles>
  )
}

Sheet.propTypes = {
  fields: PropTypes.array.isRequired,
  keyName: PropTypes.string,
  values: PropTypes.array.isRequired,
  handleSheetStateChange: PropTypes.func.isRequired,
  handleSelectedPidsChange: PropTypes.func.isRequired,
  sheetState: PropTypes.object,
  selectedPids: PropTypes.array,
  disableRowSelection: PropTypes.bool,
  showOnlySelectedPids: PropTypes.bool,
  filtersEnabled: PropTypes.bool,
  refPidType: PropTypes.string.isRequired,
}

export default React.memo(Sheet)
