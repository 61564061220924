import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, Card, Table } from 'react-bootstrap'
import LiftUpStepForm from './lift_up_step_form'
import styled from 'styled-components'
import LiftUpHistory from './liftup_history.js'
import { HelpPopper, bootstrapSelectStyle } from '../../utils'
import Select from 'react-select'

// need to be placed outside the function Component otherwise it will be redefined on every run cousing a complete remount
const Styles = styled.div`
  .liftup-manager {
    background-color: lavender;
  }
  #steps {
    overflow: auto;
    ul {
      padding-inline-start: 0;
      li {
        list-style-type:none;
      }
    }
  }
`

function LiftUpManager({
  workbook,
  liftUpHistory,
  handleLiftUpHistoryChange,
  disabled,
  fieldNameRegexp,
}) {
  const [showNewStepForm, setShowNewStepForm] = useState(false)

  const addNewStep = (newStep) => {
    setShowNewStepForm(false)
    liftUpHistory.addStep(newStep)
    handleLiftUpHistoryChange(liftUpHistory.data)
  }

  const removeStep = (i) => {
    liftUpHistory.removeStepAt(i)
    handleLiftUpHistoryChange(liftUpHistory.data)
  }

  const changeStepAfterField = (step, newAfterField) => {
    liftUpHistory.replaceStepAt(step.idx, { ...step.data, afterField: newAfterField })
    handleLiftUpHistoryChange(liftUpHistory.data)
  }

  const handleCancel = () => {
    setShowNewStepForm(false)
  }

  const visibleParentFieldsAt = (step) => {
    return _.map(_.pickBy(workbook[step.sheetId].parent.fields, f => (f.type == 'liftedUp' && f.liftedUpStep < step.idx) || f.type == 'orig'), 'name')
  }

  const visibleParentFieldsSelectOptionsAt = (step) => {
    return [ { value: 'at the beginning', label: 'at the beginning' }, ..._.map(visibleParentFieldsAt(step), fieldName => ({ value: fieldName, label: fieldName }))]
  }

  useEffect(() => {
    handleCancel() // reset form if something change in workbook
  }, [workbook])

  const typeSpecificCells = (step) => {
    switch (step.type) {
      case 'agg':
        return (
          <>
            <td>{`AGG ${step.formula}`}</td>
            <td>
              {step.sheetId}
              .
              <strong>{step.field}</strong>
            </td>
            <td>
              {workbook[step.sheetId].parent.id}
              .
              <strong>{step.name}</strong>
            </td>
          </>
        )
      case 'exp':
        return (
          <>
            <td>EXP</td>
            <td>
              <ul>
                {step.fields.map(f => (
                  <li key={f}>
                    {step.sheetId}
                    .
                    <strong>{f}</strong>
                  </li>
                ))}
              </ul>
              {' '}
            </td>
            <td>
              <ul>
                {step.fields.map(f => (
                  <li key={f}>
                    {workbook[step.sheetId].parent.id}
                    .
                    <strong>{`${step.prefix}${workbook[step.sheetId].isMadeOfScalars ? '' : f.concat('_')}*`}</strong>
                  </li>
                ))}
              </ul>
            </td>
          </>
        )
    }
  }

  return (
    <Styles>
      <Card bsPrefix="card card-outline card-secondary liftup-manager">
        <Card.Header>
          <Card.Title>
            <strong>LiftUp</strong>
            <HelpPopper>
              <b>
                You can move
                <i> (lift up)</i>
                {' '}
                data from secondary sheets up to parent sheets
              </b>
              . This can be done by defining and applying one liftUp step after another. Each step define the way one or more sheet fields are
              <i> lifted up</i>
              {' '}
              to the parent sheet. Each step generate a layer on which the next step is applied. This means that a lifted up field can be lifted up multiple times (till the main sheet)
            </HelpPopper>
          </Card.Title>
          <div className="card-tools">
            <button className="btn btn-tool" data-card-widget="collapse" type="button"><i className="fas fa-minus" /></button>
          </div>
        </Card.Header>
        {
          liftUpHistory.steps.length && workbook.main.values.length
            ? (
              <Card.Body>
                <div id="steps">
                  <Table hover size="sm">
                    <thead>
                      <tr>
                        <th>step #</th>
                        <th>type</th>
                        <th>from</th>
                        <th>to</th>
                        <th>
                          after field
                          <HelpPopper>
                            You can move the lifted up field after the field you want. Leave it blank to put it at the end
                          </HelpPopper>
                        </th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {
                        liftUpHistory.steps.map((step, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            {typeSpecificCells(step)}
                            <td>
                              <Select
                                isClearable
                                isMulti={false}
                                menuPosition="fixed"
                                onChange={o => changeStepAfterField(step, o?.value)}
                                options={visibleParentFieldsSelectOptionsAt(step)}
                                styles={bootstrapSelectStyle}
                                value={{ value: step.afterField, label: step.afterField || <i className="text-muted">at the end</i> }}
                              />
                            </td>
                            <td>
                              <Button disabled={step.isInUse() || disabled} onClick={() => removeStep(i)} variant="link">
                                <i className="fas fa-trash text-danger" />
                              </Button>
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
              )
            : null
        }
        <Card.Footer>
          {
            showNewStepForm
              ? (
                <LiftUpStepForm
                  disable={showNewStepForm}
                  fieldNameRegexp={fieldNameRegexp}
                  handleCancel={handleCancel}
                  handleSubmit={addNewStep}
                  step={liftUpHistory[showNewStepForm]}
                  workbook={workbook}
                />
                )
              : (
                <div className="float-right">
                  <Button disabled={disabled} onClick={() => setShowNewStepForm(true)} size="sm" variant="primary">
                    <i className="fas fa-plus" />
                    {' '}
                    New step
                  </Button>
                </div>
                )
            }

        </Card.Footer>
      </Card>
    </Styles>
  )
}

LiftUpManager.propTypes = {
  workbook: PropTypes.object.isRequired,
  liftUpHistory: PropTypes.instanceOf(LiftUpHistory),
  handleLiftUpHistoryChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  fieldNameRegexp: PropTypes.instanceOf(RegExp).isRequired,
}

const memoizedLiftUpManager = React.memo(LiftUpManager)
memoizedLiftUpManager.displayName = 'LiftUpManager'

export default memoizedLiftUpManager
